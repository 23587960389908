@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-popup-profile {
  flex: 1;

  .button {
    align-self: center;
    max-width: fit-content;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__title {
    font-size: 3rem;
  }

  &__info-line {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  &__info-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    // border-top: 0.15rem solid $color-black;
    padding-bottom: 1rem;
    border-bottom: 0.15rem solid $color-black;

    .button {
      align-self: flex-start;
      width: fit-content;
    }

    &--avatar {
      margin: 1rem 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 0;
      margin-bottom: 0;

      &__media {
        height: 20rem;
        width: 20rem;
        border-radius: 50%;
        margin-bottom: 2rem;
      }

      &__buttons {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  &__action-link {
    font-size: 1.5rem;
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
  }

  &__input {
    border-radius: $border-radius-default;
    padding: 1rem 1.5rem;
    max-width: 35rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
  }

  &__bottom {
    margin-top: 2rem;
  }
}
