@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.section {
  width: 100%;
  margin-top: 7rem;
  margin-bottom: 10rem;

  &__title {
    font-size: $font-size-body-heading-huge;
    margin-bottom: 2rem;
    width: 100%;

    &--partners {
      margin-bottom: 3.5rem;
    }

    &__highlight {
      color: $color-primary;
    }
  }

  &__intro {
    font-size: $font-size-default;
    margin-bottom: 5rem;

    &--small-margin {
      margin-bottom: 1rem;
    }
  }
}
