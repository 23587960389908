@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "@pathofdev/react-tag-input/build/index.css";

.client-form {
  &__label {
    font-size: $font-size-body-paragraph;
    font-weight: 500;
    color: $color-grey-dark-2;
    display: flex;
    align-items: center;
    transform: translateY(0rem);
    transition: all $transition-time-default ease-out;
    padding-left: 1.5rem;
    margin-top: 0.5rem;

    svg {
      margin-right: 1rem;
    }
  }

  &__input {
    font-size: $font-size-body-paragraph;
    border-radius: $border-radius-default;
    padding: 1rem 1.5rem;
    border: 0.15rem solid $color-grey-dark;
    transition: all $transition-time-default ease;

    &:hover,
    &:focus {
      outline: none;

      &::placeholder {
        color: $color-black;
      }
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    &:focus {
      transform: translateY(-1rem);
      box-shadow: $box-shadow-default $color-shadow-black;
      & ~ svg {
        -webkit-transform: translateY(-1rem);
        transform: translateY(-1rem);
      }
    }

    // TODO: Add a class upon setting the value if the input is non-empty
    &:valid.client-form__input--non-empty {
      border-color: $color-green !important;

      &:focus {
        box-shadow: $box-shadow-default $color-shadow-green !important;
      }

      ~ .client-form__label {
        // color: $color-green;
      }
    }

    &:invalid.client-form__input--non-empty {
      border-color: $color-primary !important;
      &:focus {
        box-shadow: $box-shadow-default $color-shadow-primary !important;
      }

      ~ .client-form__label {
        // color: $color-primary;
      }

      ~ .client-form__button {
        // color: $color-primary;
      }
    }

    &:placeholder-shown {
      border-color: $color-grey-dark;

      & ~ .client-form__label {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(-3rem);
        transform: translateY(-3rem);
      }
    }

    &--grey {
      background-color: $color-grey;
    }

    &--textarea {
      flex: 1;
      width: 100%;
      resize: vertical;
    }

    &__container {
      position: relative;

      svg {
        position: absolute;
        right: 1.5rem;
        top: 0.75rem;
        font-size: 2.5rem;
        transition: all $transition-time-default ease;
        cursor: pointer;
      }
    }
  }

  &__button {
    padding: 0 1rem;
    font-size: $font-size-body-heading-xl;
    background-color: $color-white;
    height: fit-content;
    width: fit-content;

    &--success {
      color: $color-green;
    }

    &:focus {
      & ~ .client-form__input {
        transform: translateY(-1rem);
      }
    }
  }
}
