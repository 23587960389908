@import "../abstracts/variables";
@import "../abstracts/mixins";

.back-to-top-button {
  position: fixed;
  bottom: 6rem;
  right: 5rem;
  padding: 1rem;
  background-color: $color-primary;
  border: 0.3rem solid transparent;
  border-radius: $border-radius-round;
  color: $color-white;
  font-size: $font-size-body-heading-large;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all $transition-time-default ease;
  //   background-color: transparent;
  //   mix-blend-mode: overlay;
  transform: translateX(10rem);
  z-index: 400;

  @include respond(tab-port) {
    right: 3rem;
  }

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-primary;
    border-color: $color-primary;
  }

  &--visible {
    transform: translateX(0);
  }
}
