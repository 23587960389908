@import "../abstracts/variables";
@import "../abstracts/mixins";

.static-page {
  display: flex;
  flex-direction: column;

  &__heading {
    font-size: $font-size-body-heading-xl;
    margin-bottom: 5rem;
  }

  &__section {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    &--list {
    }

    &__title {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 3rem;

      &--point {
        &__number {
          font-size: $font-size-body-heading-xl;
          color: $color-primary;
          border-left: 0.5rem solid $color-primary;
          padding-left: 1rem;
          margin-right: 1.5rem;
        }
      }
    }

    &__paragraph {
      font-size: $font-size-body-paragraph;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    &__list {
      width: 100%;
      padding-left: 3.5rem;
      margin-bottom: 2rem;
      list-style-type: decimal;

      &--ul {
        list-style-type: disc;
      }

      &__item {
        font-size: $font-size-body-paragraph;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
