@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.services-section {
  display: flex;
  flex-direction: column;

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 7rem;
    column-gap: 7rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }
  }

  &__service {
    position: relative;
    height: 150px;
    flex: 1;
    // display: flex;

    @include respond(tab-port) {
      height: fit-content;
      width: 100%;
    }

    &:hover {
      .services-section__service__index {
        transform: translateX(-0.5rem) translateY(-0.3rem);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }
      .services-section__service__description {
        transform: translateX(0.5rem);
      }
    }

    &__index {
      font-size: $font-size-body-heading-large;
      font-weight: bold;
      color: $color-white;
      position: absolute;
      left: 0;
      top: 0;

      background-color: $color-primary;
      box-shadow: $box-shadow-default $color-shadow-primary;
      padding: 1rem 2rem;
      border-radius: $border-radius-round;

      transition: all $transition-time-default ease-in-out;
    }

    &__description {
      font-size: $font-size-body-heading-small;
      // border-bottom: 0.3rem solid $color-primary;

      //   position: absolute;
      //   left: 2.5rem;
      //   top: 2.5rem;
      margin-left: 2.5rem;
      margin-top: 2.5rem;
      background-color: $color-grey;
      border-radius: $border-radius-default;
      padding: 2rem 2.5rem;
      padding-left: 4rem;
      width: 100%;
      max-width: 500px;
      height: 90%;
      //   align-self: flex-end;
      display: flex;
      align-items: center;
      box-shadow: $box-shadow-default $color-shadow-black;
      transition: all $transition-time-default ease-in-out;

      @media screen and (max-width: 1300px) {
        font-size: $font-size-body-paragraph;
      }

      @include respond(tab-port) {
        width: 90%;
        max-width: 90%;
      }
    }
  }
}
