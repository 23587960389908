@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.partners-section {
  display: flex;
  flex-direction: column;

  &__logos {
    margin-top: 3rem;

    &__logo {
      height: 100%;
      filter: grayscale(1);
      transition: all $transition-time-default ease-out;

      &:hover {
        filter: none;
      }
    }
  }
}
