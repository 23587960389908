@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-panel-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  &--30-70 {
    .admin-panel-screen__content__left {
      max-width: 30%;
      margin-right: 0;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    .admin-panel-screen__content__right {
      width: 70%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &--40-60 {
    .admin-panel-screen__content__left {
      max-width: 40%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    .admin-panel-screen__content__right {
      width: 60%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &--45-55 {
    .admin-panel-screen__content__left {
      max-width: 45%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    .admin-panel-screen__content__right {
      width: 55%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    margin-bottom: 1.5rem;

    &--no-work {
      align-items: center;
      margin-top: 10rem;
    }
  }

  &__empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    align-self: center;
    margin-top: 10rem;

    &__message {
      font-size: $font-size-body-heading-medium;
    }
  }

  &__content {
    display: flex;
    justify-content: space-evenly;
    height: 90%;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
      row-gap: 2rem;
    }

    &--users {
      @media screen and (max-width: 1200px) {
        flex-direction: column !important;
      }
    }

    &--left-only {
      justify-content: center;
      flex: 1;
      width: 100%;
    }

    &--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center !important;
      width: 100%;
      align-self: center;
    }

    &__left {
      width: 50%;
      padding-right: 3rem;
      flex: 1;
      align-self: flex-start;
      height: 100%;

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding-right: 0;
      }
    }

    &__right {
      width: 50%;
      flex: 1;
      max-width: 80%;
      align-self: flex-start;
      height: 90%;
      overflow-y: scroll;
    }

    &__no-work {
      font-size: $font-size-body-heading-medium;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 1200px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
