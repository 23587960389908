@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-dashboard {
  width: 100%;
  height: 100%;

  &__welcome {
    font-size: 4rem;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 1200px) {
    .admin-panel-screen__content {
      flex-direction: column !important;
    }
  }
}
