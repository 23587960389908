@import "../abstracts/variables";
@import "../abstracts/mixins";

.searches-list {
  display: flex;
  flex-direction: column;
  // width: fit-content;
  max-width: 70rem;
  margin-top: 2rem;

  &--empty {
    width: 100%;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;

    @include respond(phone) {
      align-items: flex-start;
      flex-direction: column-reverse;
      row-gap: 1rem;
    }
  }

  &__url-container {
    padding-left: 2rem;
    display: flex;
    justify-content: flex-end;

    @include respond(phone) {
      padding-left: 0;
    }

    &__url {
      padding: 1rem 2rem;
      width: max-content;
      cursor: pointer;
      background-color: $color-grey-light-2;
      border-radius: $border-radius-default;
      transition: all $transition-time-default;
      outline: none;
      border: none;

      &:hover,
      &:focus {
        background-color: $color-grey-light;
      }
    }
  }

  &__item {
    position: relative;
    background-color: $color-white;
    // width: fit-content;
    max-width: 70rem;
    padding-top: 1rem;

    border: 0.15rem solid $color-grey-dark;
    border-radius: $border-radius-default;
    font-size: $font-size-body-paragraph;
    transition: all $transition-time-default ease-out;

    &:hover {
      //   transform: translateY(-1rem);
      //   box-shadow: $box-shadow-list-item;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &__title {
      font-size: $font-size-body-heading-small;
      font-weight: 600;
      padding: 0 2rem;
      margin-bottom: 0.5rem;
    }

    &__frequency {
      padding: 0 2rem;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__filters {
      margin-top: 1rem;
      padding: 0 2rem;

      &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 2rem;

        &--short {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
        }

        &__item {
          display: flex;
          flex-direction: column;
          justify-content: center;

          padding-left: 1rem;

          &__title {
            margin-left: -1rem;
            font-weight: 500;
            color: $color-primary;
            text-transform: capitalize;
          }

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          &--short {
            &:not(:last-child) {
              margin-bottom: 0;
              margin-right: 0.5rem;
            }
          }
        }
      }

      &__title {
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }

    &__form {
      padding: 0 2rem;

      &__input {
        margin-bottom: 2rem;
        width: 100%;

        &--select {
          border: none;
          padding: 0;
        }
      }
    }

    &__buttons {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      border-top: $border-size-listing-item solid $color-grey-dark;
      margin-top: 1rem;

      &__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          font-size: $font-size-body-heading-large;
          margin-bottom: 0.5rem;
          align-self: center;
        }
      }
    }
  }

  &__empty {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__message {
      display: flex;
      flex-direction: column;
      font-size: $font-size-body-paragraph;
      align-items: center;
      justify-content: center;

      .button {
        margin: 1rem 0;
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 1rem;

    &__item {
      a {
        padding: 0.5rem 1rem;
        font-size: $font-size-body-heading-small;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all $transition-time-default ease;

        &:hover,
        &:focus {
          background-color: $color-grey-light-2;
          // color: $color-grey-dark;
        }
      }

      &--disabled {
        a {
          color: $color-grey-light;
          cursor: not-allowed;
        }
      }
      &--active {
        a {
          background-color: $color-primary;
          color: $color-white;

          &:hover,
          &:focus {
            background-color: $color-primary;
          }
        }
      }

      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
    }
  }
}
