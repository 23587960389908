.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10rem;
  z-index: 0;

  @include respond(phone) {
    margin-top: 15rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-grey;

    &__middle {
      display: flex;
      flex-direction: column;
      width: 65vw;
      padding: 7.5rem 0;
      padding-bottom: 5rem;

      @include respond(phone) {
        width: 100%;
        padding: 3rem 2rem;
      }

      &__socials {
        display: flex;
        justify-content: flex-start;
        column-gap: 1rem;

        &__wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-top: 2rem;

          @media screen and (max-width: 800px) {
            align-items: flex-start;
            margin-top: 5rem;
          }
        }

        &__inner {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
        }

        &__heading {
          font-size: $font-size-body-heading-small;
        }

        &__icon {
          width: 30px;
          height: 30px;
          color: $color-black;
          transition: all $transition-time-default $transition-function-default;

          &:hover,
          &:focus {
            color: $color-primary;
          }
        }
      }

      &__columns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 3rem;
        row-gap: 5rem;
      }

      &__column {
        display: flex;
        flex-direction: column;
        font-size: $font-size-body-paragraph;
        color: $color-black;
        max-width: 33%;

        @include respond(big-tab-land) {
          max-width: 40rem;
        }
        @include respond(phone) {
          max-width: 100%;
        }

        &__heading {
          font-size: $font-size-body-heading-medium;
          margin-bottom: 1.5rem;
        }

        &__item {
          display: flex;
          flex-direction: column;

          &:nth-child(2) {
            display: block;
          }

          &:not(:last-child) {
            margin-bottom: 2rem;
          }

          &__icon {
            margin-right: 1rem;
          }

          &__bold {
            // margin-right: 0.5rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            width: fit-content;
          }

          &__text {
            display: block;
            // margin-left: 0.5rem;
          }

          &__link {
            &:last-child {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    &__bottom-row {
      width: 65vw;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2.5rem;
    }
  }

  &__language-switch {
    display: flex;
    align-items: center;
    font-size: $font-size-body-paragraph;
    background-color: $color-white;
    color: $color-primary;
    border-radius: 2rem;
    padding-right: 1rem;
    cursor: pointer;
    // border: 0.25rem solid $color-primary;

    svg {
      font-size: 200%;
    }

    &__options {
      padding: 0.3rem 0;
      background-color: transparent;
      border: none;
      outline: none;
      font-weight: 550;
      margin-left: 0.5rem;
      color: $color-primary;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 12rem;

      // padding: 0.5rem 0;
    }

    &__option {
      margin-right: 1rem;
    }
  }

  &__image {
    &__wrapper {
      background-color: $color-grey;
      margin-bottom: -1rem;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;

    &__content {
      display: flex;
      width: 65vw;
      align-items: center;
      justify-content: space-between;

      @include respond(phone) {
        width: 100vw;
        padding: 1rem 2rem;
        padding-top: 2rem;
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }
  }

  &__copy {
    display: flex;
    width: 65vw;
    align-self: flex-end;
    margin: 1.75rem 0;
    font-size: $font-size-default;
    font-weight: 500;
    color: $color-white;

    @include respond(phone) {
      width: 100%;
      justify-content: flex-start;
    }

    .footer__icon {
      margin: 0 0.5rem;
    }
  }

  &__icon {
    height: 2rem;
    align-self: center;
  }
}
