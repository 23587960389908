// Colors

$color-white: #fff;
$color-grey: #f5f5f5;
$color-grey-light: hsl(0, 0%, 80%);
$color-grey-light-2: darken($color-grey, 5);
$color-grey-medium: #b3b3b3;
$color-grey-dark: darken($color-grey, 40%);
$color-grey-dark-2: darken($color-grey, 50%);
$color-black: #000;

$color-primary: #bd0320;
$color-secondary: #03bda0;

$color-map-pin: #ff3333;
$color-red: #ff2a00;
$color-purple: #6e33c0;
$color-green: #33cd32;
$color-orange: #f1b948;
$color-yellow: #e2e228;

$color-shadow-black: rgba(
  $color: $color-black,
  $alpha: 0.15,
);

$color-shadow-primary: rgba(
  $color: $color-primary,
  $alpha: 0.15,
);

$color-shadow-green: rgba(
  $color: $color-green,
  $alpha: 0.15,
);

// Gradients

$linear-gradient-primary: linear-gradient(
  to top right,
  $color-primary,
  lighten($color-primary, 10%)
);

// GRID

$grid-width: 114rem;
$grid-width-small: 50rem;
$grid-width-big: 140rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-vertical-big: 10rem;
$gutter-vertical-huge: 20rem;
$gutter-horizontal: 6rem;

// Font sizes

$font-size-default: 1.6rem;
$font-size-body-paragraph: 1.5rem;
$font-size-body-small: 1.3rem;
$font-size-body-heading-small: 1.7rem;
$font-size-body-heading-medium: 1.9rem;
$font-size-body-heading-large: 2.1rem;
$font-size-body-heading-xl: 3rem;
$font-size-body-heading-huge: 3.5rem;
$font-size-body-heading-massive: 4.5rem;

$font-size-listing-xs: 1.3rem;
$font-size-listing-small: 1.5rem;
$font-size-listing-default: 1.7rem;
$font-size-listing-medium: 1.9rem;
$font-size-listing-large: 2.3rem;
$font-size-listing-huge: 3rem;

$font-size-menu-item: 1.4rem;
$font-size-menu-item-small: 1.35rem;

// Border

$border-radius-default: 0.4rem;
$border-radius-round: 1rem;
$border-radius-default-button: 0.3rem;
$border-radius-tag: 3rem;

$border-size-default: 0.15rem;
$border-size-listing-item: 0.1rem;

// Box shadow

$box-shadow-default: 0 0.3rem 0.3rem 0.3rem;
$box-shadow-medium: 0 0.5rem 0.5rem 0.5rem;
$box-shadow-large: 0 0.7rem 0.7rem 0.7rem;
$box-shadow-list-item: 0.25rem 0.3rem 0.5rem 0.03rem $color-shadow-black,
  -0.25rem -0.3rem 0.5rem 0.03rem $color-shadow-black;

$box-shadow-button-hover: 0 0.3rem 0.7rem 0.5rem;
$box-shadow-button-active: 0 0.3rem 0.3rem 0.3rem;

// Timing

$splash-screen-delay: 3s;

// Transitions

$transition-time-slow: 0.4s;
$transition-time-default: 0.25s;
$transition-time-medium: 0.15s;
$transition-time-fast: 0.1s;

$transition-function-default: cubic-bezier(0.39, 0.575, 0.565, 1);

// Movement

$translateY-button-hover: -0.15rem;

// Font family

$font-family-default: "Montserrat", Arial, Helvetica, sans-serif;
