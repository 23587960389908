@import "../abstracts/variables";
@import "../abstracts/mixins";

.chatroom {
  flex: 1;
  max-height: 100%;
  position: relative;
  background-color: $color-white;
  border-radius: $border-radius-default;
  overscroll-behavior: none !important;

  &__current {
    width: 70%;
    max-height: 100%;
    margin-bottom: 1rem;
    border-radius: $border-radius-default;
    display: flex;
    justify-content: center;
    overscroll-behavior: none !important;

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 0;
      align-self: center;
    }
  }

  &__empty {
    align-self: center;
    &__message {
      font-size: 1.7rem;
    }
  }

  &__scroll-to-bottom {
    background-color: $color-white;
    color: $color-primary;
    border-radius: 50%;
    padding: 0.5rem 1rem;
    height: 4.1rem;
    width: 4.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    cursor: pointer;
    border: 0.3rem solid $color-primary;

    transform: scale(0);
    transition: $transition-time-default ease;

    &:focus,
    &:hover {
      outline: none;
      background-color: $color-primary;
      color: $color-white;
    }

    &--visible {
      transform: scale(1);
    }

    &__wrapper {
      position: absolute;
      bottom: 10vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    // flex-shrink: 0;
    max-height: 20%;
    padding: 1.5rem 2rem;
    padding-right: 2.5rem;
    background-color: $color-white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($color-black, 0.3);
    margin-bottom: 0.5rem;

    &__left {
      &--centered {
        display: flex;
        align-items: center;
      }
    }

    &__name {
      margin: 0;
    }

    &__participants-count {
      font-size: 1.5rem;
    }

    // EXPERIMENTAL
    &__search {
      position: relative;
      overflow: hidden;
      height: 60%;
      display: flex;
      align-items: center;

      &--open {
        @media screen and (max-width: 1000px) {
          height: auto;
        }

        .chatroom__header__search__input {
          width: 25rem;
          padding: 1rem 0;
          padding-left: 1.5rem;
        }

        .chatroom__header__search__icon {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &__container {
        // flex: 1;
        align-self: flex-end;
        position: absolute;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        right: 5rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1000px) {
          &--open {
            width: 100%;
            bottom: -9rem;
            left: 0;
            top: unset;
            right: unset;
            background-color: $color-grey;
            padding: 2rem 2rem;
            justify-content: space-between;

            .chatroom__header__search__icon {
              border-right: none;
            }
          }
        }
      }

      &__controls {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-right: 1rem;
        user-select: none;

        @media screen and (max-width: 1000px) {
          flex-direction: column;
          row-gap: 0.5rem;
        }
      }

      &__count {
        font-size: $font-size-body-paragraph;
      }

      &__arrows {
        display: flex;
        column-gap: 1rem;
        margin-right: 1rem;

        @media screen and (max-width: 1000px) {
          margin-right: 0;
        }

        &__arrow {
          font-size: 3rem;
          cursor: pointer;
          border-radius: 50%;
          transition: all $transition-time-default ease;

          &:hover {
            background-color: $color-grey;
          }

          &--up {
          }

          &--down {
            transform: rotate(180deg);
          }
        }
      }

      &__input {
        border: none;
        outline: none;
        background-color: $color-grey;
        border-radius: $border-radius-default;
        margin: 0;
        width: 0;
        height: 100%;
        margin-right: 5rem;
        align-self: flex-start;
        font-size: $font-size-body-paragraph;
        color: $color-black;
        transition: all $transition-time-default ease;

        @media screen and (max-width: 1000px) {
          background-color: $color-white;
        }

        &::placeholder {
          color: $color-grey-dark;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        height: 100%;
        position: absolute;
        width: 5rem;
        height: 100%;
        top: 0;
        right: 0;
        font-size: 2.1rem;
        padding: 0.5rem;
        border: none;
        outline: none;
        text-align: center;
        cursor: pointer;
        color: $color-black;
        border-right: 0.15rem solid $color-black;
        // background-color: $color-primary;
        // border-radius: $border-radius-default;
        transition: all $transition-time-default ease, border-right 0 ease;
      }
    }
    // EXPERIMENTAL

    &__options-button {
      font-size: 2rem;
      align-self: center;
      cursor: pointer;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 81%;
    flex: 1;
    padding: 2rem;
    overscroll-behavior: none !important;
    width: 100%;

    &__date-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 2rem;

      &__date {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.3rem;
        // border: 0.1rem solid $color-black;
        display: inline-block;
        align-self: center;
        padding: 0.3rem 2rem;
        border-radius: 1.5rem;
        background-color: $color-grey;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    padding: 1rem 2rem;
    padding-bottom: 2rem;
    // padding-top: 3rem;
    background-color: $color-white;
    // margin-top: 2rem;
    // transform: translateY(1rem);
    flex: 1;
    position: absolute;
    bottom: 0;
    left: 0;

    &__input-container {
      width: 100%;
      height: 4rem;
      position: relative;
    }

    &__message-input {
      // box-shadow: 0 -0.3rem 0.3rem rgba($color-black, 0.13);
      box-shadow: 0 0.25rem 0.75rem rgba($color-black, 0.15);
      font-size: 1.5rem;
      padding: 1rem 2rem;
      width: 100%;
      height: 100%;
      max-height: 100%;
      align-self: flex-start;
      word-break: break-word;
      overflow: scroll;
      border-radius: 3rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      background-color: $color-grey;
      border: none;

      &:focus {
        outline: none;
      }

      @include respond(phone) {
        border-bottom-right-radius: 3rem;
        border-top-right-radius: 3rem;
      }
    }

    &__emoji-btn {
      display: flex;
      position: absolute;
      background-color: $color-grey;
      right: 1rem;
      bottom: 50%;
      transform: translateY(50%);
      padding: 0.6rem;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      transition: all $transition-time-default $transition-function-default;

      &:hover {
        background-color: $color-grey-light-2;
      }

      img {
        width: 2.5rem;
        height: 2.5rem;
        // background-color: $color-grey;
        border-radius: 50%;
      }
    }

    &__send {
      cursor: pointer;
      background-color: $color-primary;
      // box-shadow: 0 -0.3rem 0.3rem rgba($color-black, 0.13);
      box-shadow: 0 0.5rem 0.75rem rgba($color-black, 0.15);
      color: $color-white;
      font-size: 1.7rem;
      height: 4rem;
      max-height: 100%;
      width: 7rem;
      padding: 1rem;
      padding-left: 0.7rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      transition: $transition-time-default ease;
      // transform: translateX(-3.5rem);

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-primary;
      }

      @include respond(phone) {
        display: none;
      }
    }
  }

  &__backdrop {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-default;

    // transition: $transition-time-default ease;
    transform: scale(1);

    &--hidden {
      transform: scale(0);
      visibility: hidden;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $border-radius-default;

      background-color: rgba($color-black, 0.5);
    }
  }

  &__options-menu {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    align-self: center;
    background-color: $color-grey;
    padding: 1.5rem 2rem;
    width: 70%;
    overflow: hidden;
    border-radius: $border-radius-default;
    transform: scale(1);
    transition: $transition-time-default ease;

    @media screen and (max-width: 1000px) {
      width: 95%;
    }

    &--hidden {
      transform: scale(0);
    }

    &__close {
      font-size: 2rem;
      font-weight: bold;
      cursor: pointer;
      color: $color-black;
      transform: rotate(45deg);
      margin-left: 5rem;
      width: fit-content;
      transition: $transition-time-default ease;

      &:hover,
      &:focus {
        color: $color-primary;
        outline: none;
      }
    }

    &__name-form {
      display: flex;
      width: 100%;

      &__input {
        font-size: 1.9rem;
        padding: 0.5rem 1.5rem;
        overflow: hidden;
        width: 100%;
        max-width: 50rem;
      }

      &__save {
        font-size: 1.7rem;
        margin-left: 1rem;

        svg {
          margin-right: 0 !important;
        }
      }
    }

    &__name {
      display: flex;

      &__text {
        font-size: 1.9rem;
      }

      &__edit {
        & {
          font-size: 1.7rem;
          margin-left: 1.5rem;
          transform: translateY(-0.2rem);

          border: none;
          outline: none;
          cursor: pointer;
          transition: $transition-time-default ease;

          &:hover,
          &:focus {
            color: $color-primary;
            outline: none;
          }
        }
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
    }

    &__middle {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__participants {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2rem;
        max-height: 40rem;
        max-width: 100rem;

        &__title {
          font-size: 1.7rem;
          margin: 1rem 0;
        }

        &__participant {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }

          &__name {
            font-size: 1.5rem;
            align-self: center;
          }

          &__button-group {
            display: flex;
            margin-left: 1rem;

            .button {
              &:not(:last-child) {
                margin-right: 1rem;
              }
            }
          }

          &__button {
            font-size: 1rem;
            border-radius: $border-radius-default;
            border: none;
            color: $color-white;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            padding: 1rem 2rem;
            transition: $transition-time-default ease;

            &:not(:last-child) {
              margin-right: 1rem;
            }

            &:hover,
            &:focus {
              background-color: $color-black;
              color: $color-white;
            }

            &--remove {
              background-color: $color-primary;
            }
          }
        }
      }

      &__buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 2rem;
        margin-bottom: 1rem;

        .button {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }

        &__button {
          font-size: 1.3rem;
          font-weight: bold;
          border: none;
          color: $color-white;
          border-radius: $border-radius-default;
          padding: 1rem 2rem;
          cursor: pointer;
          transition: $transition-time-default ease;

          &:not(:last-child) {
            margin-right: 2rem;
          }

          &:hover,
          &:focus {
            outline: none;
          }

          &--leave {
            color: $color-black;
            border: 0.1rem solid $color-black;

            &:hover,
            &:focus {
              background-color: $color-black;
              color: $color-white;
            }
          }

          &--delete {
            background-color: $color-primary;
            border: 0.1rem solid transparent;

            &:hover,
            &:focus {
              background-color: $color-white;
              border-color: $color-primary;
              color: $color-primary;
            }
          }
        }
      }

      &__add-participants {
        max-height: 40rem;
        max-width: 100rem;

        &__title {
          font-size: 1.7rem;
          margin-bottom: 1rem;
        }

        &__searchbar {
          font-size: 1.3rem;
          padding: 0.5rem 1.25rem;
          border-radius: $border-radius-default;
          border: 0.1rem solid $color-black;

          &:hover,
          &:focus {
            outline: none;
          }
        }

        &__participant {
          display: flex;
          justify-content: space-between;

          &:first-of-type {
            margin-top: 1.5rem;
          }

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }

          &__name {
            font-size: 1.5rem;
            align-self: center;
          }

          &__button {
            font-size: 1rem;
            border-radius: $border-radius-default;
            border: 0.1rem solid $color-black;
            color: $color-black;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            padding: 1rem 2rem;
            transition: $transition-time-default ease;

            &:hover,
            &:focus {
              background-color: $color-black;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.emoji-picker {
  position: absolute;
  right: 1rem;
  top: -450px;
  z-index: 300;

  @media screen and (max-width: 1000px) {
    right: unset;
    z-index: 1;
  }
}
