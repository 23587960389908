@import "../abstracts/variables";
@import "../abstracts/mixins";

.chat-page {
  &__wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    overscroll-behavior: none !important;
    background-color: $color-grey;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;

    @media screen and (max-width: 1000px) {
      justify-content: center;
    }
  }

  &__drawer {
    width: 100% !important;
    max-width: 60rem !important;
    height: 100svh !important;
    display: flex !important;
    flex-direction: column !important;
    overscroll-behavior: none !important;

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      padding-bottom: 0.5rem;
      background-color: $color-grey;

      &__title {
        flex: 1;
        justify-content: center;
        font-size: $font-size-body-heading-medium;
      }

      &__close {
        flex: 0;
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: transparent;
        padding: 0.5rem;
        transition: all $transition-time-default $transition-function-default;

        &:hover,
        &:focus,
        &:active {
          background-color: $color-grey-light;
        }
      }
    }
  }
}
