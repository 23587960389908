@import "../abstracts/variables";
@import "../abstracts/mixins";

.icon-input {
  height: fit-content;
  position: relative;

  &__input {
    &:hover,
    &:focus,
    &:focus-within {
      & ~ .icon-input__icon {
        color: $color-primary;
      }
    }

    &--no-padding {
      .searchbar__control {
        padding-left: 0.5rem;
      }
    }

    &--left {
      padding-left: 3rem;
    }

    &--right {
      padding-right: 3rem;
    }
  }

  &__icon {
    position: absolute;
    left: 1.15rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: $font-size-body-heading-large;
    transition: all $transition-time-fast ease;
  }
}
