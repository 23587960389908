@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "../base/animations";

.map-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  z-index: 100001;

  &--admin {
    top: -10svh;
  }

  &__bg {
    background-color: rgba($color-black, 0.5);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__map {
  }

  &__map-container {
    position: relative;
  }

  &__listing {
    &__container {
    }

    &__complex {
      &__container {
        position: absolute !important;
        z-index: 300 !important;

        min-height: 30rem;
        width: 30rem;
        right: -32rem;
        top: calc(100% - 7.8rem);
        background-color: $color-white;
        border-radius: $border-radius-default;
        transition: all $transition-time-default ease;

        &--shown {
          transform: scale(1);

          &:hover,
          &:focus {
            transform: scale(1) translateY(-0.5rem);
          }
        }

        &--hidden {
          transform: scale(0);
        }

        .swiper-button {
          &-next,
          &-prev {
            position: absolute;
            display: flex;
            align-items: center;
            bottom: 50%;
            transform: translateY(-50%);
            height: 3rem;
            width: 3rem;
            color: $color-white;
            font-size: $font-size-body-heading-large;
            background-color: $color-grey-dark;
            padding: 0.3rem;
            z-index: 301 !important;
            border-radius: $border-radius-default;
            cursor: pointer;
            transition: all $transition-time-default ease;
            outline: none !important;
            border: none !important;

            &--disabled {
              cursor: not-allowed;
              background-color: $color-grey-light;

              &:hover,
              &:focus,
              &:active {
                transform: translateY(-50%) !important;
                background-color: $color-grey-light !important;
                box-shadow: none !important;
              }
            }

            &:hover,
            &:focus {
              transform: translateY(calc(-50% - 0.3rem));
              background-color: $color-primary;
              box-shadow: $box-shadow-button-hover $color-shadow-primary;
            }

            &:active {
              box-shadow: $box-shadow-button-active $color-shadow-primary;
              transform: translateY(-50%);
            }
          }
          &-next {
            right: 0;
          }

          &-prev {
            left: 0;
          }

          &-disabled {
            box-shadow: none !important;
            transform: translateY(-50%) !important;
            background-color: rgba(
              $color: $color-grey-dark,
              $alpha: 0.75
            ) !important;
            cursor: not-allowed;
          }
        }
      }

      &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-map-pin;
        color: $color-white;
        font-size: $font-size-body-heading-small;
        font-weight: 500;
        padding: 0.5rem 2rem;
        position: absolute;
        top: calc(100% - 4.5rem);
        left: 50%;
        transform: translate(-50%, -100%);
        border-radius: $border-radius-default;
        border: 0.15rem solid $color-map-pin;
        cursor: pointer;
        transition: all $transition-time-default ease;

        &::after {
          content: "";
          position: absolute;
          bottom: -2rem;
          height: 2rem;
          left: 0;
          width: 100%;
        }
      }
    }

    &__pin {
      z-index: 1 !important;
      position: relative;
      height: 3.5rem;
      cursor: pointer;
      transition: all $transition-time-default ease-out;

      &--complex {
        width: 3rem;

        .map-overlay__listing__window {
          top: -3.5rem;
        }
      }

      &--shown {
        .map-overlay__listing__complex__count {
          background-color: $color-white !important;
          color: $color-map-pin !important;
        }
      }
    }

    &__window {
      display: flex;
      flex-direction: column;
      position: absolute;
      min-height: 30rem;
      width: 30rem;
      right: -46rem;
      background-color: $color-white;
      border-radius: $border-radius-default;
      transition: all $transition-time-default ease;
      backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      z-index: 300 !important;

      &--shown {
        transform: translateX(-50%) scale(1);
        &:hover,
        &:focus {
          transform: translateX(-50%) scale(1) translateY(-0.5rem);
        }
      }

      &--hidden {
        transform: translateX(-50%) scale(0);
      }

      &--slide {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;

        &:hover,
        &:focus {
          box-shadow: none !important;
          transform: none !important;
        }
      }

      &__top {
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex: 1;
        max-height: 25rem;

        &--empty {
          background-color: $color-grey-light-2;
        }

        &__heart {
          font-size: $font-size-body-paragraph;

          transition: all $transition-time-default ease;
          transition-delay: 0.3s;
          align-self: center;
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;

          &--fill {
            color: $color-red;
          }

          &__container {
            position: absolute;
            height: 3rem;
            width: 3rem;
            border-radius: 100rem;
            background-color: $color-white;
            box-shadow: 0 0.15rem 0.5rem $color-shadow-black;
            padding: 0.5rem;
            padding-bottom: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 5rem;
            top: 1rem;
            // border: 0.3rem solid $color-black;
            transition: all $transition-time-default ease;
            cursor: pointer;

            &:hover,
            &:focus {
              transform: scale(1.25);

              .map-overlay__listing__window__bottom__heart {
                transform: scale(1.25);
              }
            }
          }
        }
        &__close {
          font-size: $font-size-body-heading-small;

          transition: all $transition-time-default ease;
          transition-delay: 0.3s;
          align-self: center;
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          z-index: 999999;

          &--fill {
            color: $color-red;
          }

          &__container {
            position: absolute;
            height: 3rem;
            width: 3rem;
            border-radius: 100rem;
            background-color: $color-white;
            box-shadow: 0 0.15rem 0.5rem $color-shadow-black;
            padding: 0.5rem;
            padding-bottom: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 1rem;
            top: 1rem;
            // border: 0.3rem solid $color-black;
            transition: all $transition-time-default ease;
            cursor: pointer;

            &:hover,
            &:focus {
              transform: scale(1.25);

              .map-overlay__listing__window__bottom__heart {
                transform: scale(1.25);
              }
            }
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 1rem;
        width: 100%;
        cursor: pointer;

        &__row {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          * {
            max-width: 45%;
          }

          :first-child {
            align-self: flex-start;
          }

          :last-child {
            align-self: flex-end;
          }
        }
      }

      &__price {
        font-size: $font-size-body-heading-large;
        font-weight: 500;

        @include respond(tab-port) {
          font-size: $font-size-body-heading-medium;
        }
      }
      &__location {
        font-size: $font-size-body-small;
        align-self: flex-end;
        text-align: right;
      }

      &__type {
        font-size: $font-size-body-paragraph;
        min-width: 100%;
      }

      &__area {
        font-size: $font-size-body-paragraph;
      }

      &__image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--none {
          font-size: $font-size-body-heading-small;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 5rem;
    width: 6rem;
    z-index: 999999;
    border-radius: $border-radius-default;
    background-color: $color-white;
    box-shadow: $box-shadow-default $color-shadow-black;
    // padding: 1rem;
    padding: 1rem 1.5rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all $transition-time-default ease;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: $box-shadow-button-hover $color-shadow-primary;
      background-color: $color-primary;
      color: $color-white;
      transform: translateY(-0.3rem);
    }

    &:active {
      box-shadow: $box-shadow-button-active $color-shadow-primary;
      transform: translateY(0);
    }

    svg {
      font-size: $font-size-body-heading-large;
    }
  }

  &__open-drawer-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    // height: 5rem;
    // width: 6rem;
    font-size: $font-size-body-heading-large;
    font-weight: 600;
    z-index: 999999;
    border-radius: $border-radius-default;
    background-color: $color-primary;
    color: $color-white;
    box-shadow: $box-shadow-default $color-shadow-primary;
    padding: 1.5rem 2rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all $transition-time-default ease;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: $box-shadow-button-hover $color-shadow-black;
      background-color: $color-white;
      color: $color-primary;
      transform: translateY(-0.3rem);
    }

    &:active {
      box-shadow: $box-shadow-button-active $color-shadow-black;
      transform: translateY(0);
    }

    svg {
      font-size: $font-size-body-heading-xl;
      margin-left: 1rem;
    }
  }

  &__drawer {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    width: 500px !important;
    z-index: 9999999 !important;
    max-height: 100svh !important;

    @include respond(phone-land) {
      width: 100vw !important;
    }

    @include respond(tab-port) {
      width: 100vw !important;
    }

    &__close {
      display: flex;
      // justify-content: flex-end;
      // padding-right:
      width: 100%;
      justify-content: flex-end;
      margin: 1rem 0;
    }
  }
}

.cluster-marker {
  border-radius: 50%;
  background-color: darken($color: #ff4444, $amount: 5);
  font-size: $font-size-body-paragraph;
  color: $color-white;
  font-weight: bold;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  max-width: 5.5rem;
  max-height: 5.5rem;
  cursor: pointer;
  transition: all $transition-time-default $transition-function-default;

  &:hover {
    background-color: $color-white;
    color: darken($color: #ff4444, $amount: 5);

    &::after {
      background-color: $color-white;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: darken($color: #ff4444, $amount: 5);
    z-index: -1;
    border-radius: 50%;

    transition: all $transition-time-default $transition-function-default;

    animation: pulsatingCluster 1s linear infinite;
  }
}

.google-map-markers-overlay-current-active {
  z-index: 1000 !important;
}
