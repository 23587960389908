@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-transfer-popup {
  display: flex;
  align-self: center;
  justify-content: space-between;
  // flex-direction: column;
  //   width: 50%;
  max-width: 70rem;
  height: fit-content;
  padding: 2rem 3rem;
  background-color: $color-grey;
  transform: scale(0);
  transition: $transition-time-default ease;
  z-index: 1000;
  border-radius: $border-radius-default;
  //   overflow: scroll;
  // box-shadow: $box-shadow-large;

  @media screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }

  &--shown {
    transform: scale(1);
  }

  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    min-width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0);
    z-index: 1000;

    @media screen and (max-width: 1200px) {
      width: 100vw;
      height: 100svh;
      position: fixed;
      padding: 0 2rem;
    }

    &--shown {
      transform: scale(1);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    transform: scale(0);

    &--shown {
      transform: scale(1);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 0.5rem;
    }

    &__paragraph {
      font-size: $font-size-body-paragraph;
      margin-bottom: 2rem;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .button {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}
