@import "../abstracts/variables";
@import "../abstracts/mixins";

.code-input {
  &__digit {
    padding: 0.5rem 0.5rem;
    margin-bottom: 2rem;
    background-color: $color-white;
    font-size: $font-size-body-heading-large;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: $border-radius-default;
    border: 0.15rem solid $color-grey-dark;
    transition: all $transition-time-default ease;

    &:hover,
    &:focus {
      outline: none;
    }

    &:focus {
      transform: translateY(-1rem);
      box-shadow: $box-shadow-default $color-shadow-black;
    }

    &:valid.code-input__digit--non-empty {
      border-color: $color-green;

      &:focus {
        box-shadow: $box-shadow-default $color-shadow-green;
      }
    }

    &:invalid.code-input__digit--non-empty {
      border-color: $color-primary;
      &:focus {
        box-shadow: $box-shadow-default $color-shadow-primary;
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
