@import "../abstracts/variables";
@import "../abstracts/mixins";

.listing-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
  border-radius: $border-radius-round;
  background-color: $color-white;

  @include respond(tab-port) {
    margin-bottom: 0;
  }

  &__like-button {
    width: 5rem;
    height: 5rem;
    font-size: $font-size-body-heading-xl;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none !important;
    border: 0.15rem solid $color-primary;
    background-color: $color-white;
    color: $color-primary;
    // box-shadow: $box-shadow-default $color-shadow-primary;
    transition: all $transition-time-default $transition-function-default;

    &:hover,
    &:focus {
      cursor: pointer;
      color: $color-white;
      background-color: $color-primary;
      box-shadow: $box-shadow-button-hover $color-shadow-primary;
      transform: translateY(-0.3rem);
    }

    &:active {
      box-shadow: $box-shadow-button-active $color-shadow-primary;
      transform: translateY(-0.1rem);
    }

    &__wrapper {
      z-index: 1;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      background-color: $color-grey-light;
      border: 0.75rem solid $color-white;
      border-radius: 100%;
      // width: 100%;
      // display: flex;
      // justify-content: flex-end;
    }
  }

  &__top {
    display: flex;

    @include respond(tab-port) {
      flex-direction: column;
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 60%;
      height: 100%;
      max-height: 65rem;
      min-height: 30rem;
      flex-direction: column;

      @include respond(tab-port) {
        max-height: 50rem;
        width: 100%;
        justify-content: center;
      }
    }

    &__right {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2rem 3rem;
      padding-top: 0;

      @include respond(tab-port) {
        max-height: unset;
        width: 100%;
        justify-content: center;
        padding: 2rem 0;
      }

      &__views-container {
        display: flex;
        column-gap: 0.5rem;
        font-size: $font-size-body-heading-small;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    font-size: $font-size-body-paragraph;
    position: relative;

    &__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;
      margin: 3rem 0;
      // background-color: $color-grey;
      border-radius: $border-radius-default;
      // padding: 2rem 3rem;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &--broker {
        // background-color: transparent;
        padding: 0;

        @include respond(tab-port) {
          margin-top: 3rem;
          margin-bottom: -5rem;
        }
      }

      &__heading {
        font-size: $font-size-body-heading-xl;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      &__body {
        display: flex;
        align-items: flex-start;

        @include respond(tab-port) {
          flex-direction: column;
        }

        &--location {
          @media screen and (max-width: 1400px) {
            flex-direction: column;
          }
        }

        &__section {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &__amenities {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    @include respond(tab-port) {
      margin-bottom: 0;
      row-gap: 1rem;
    }

    &__item {
      border-radius: $border-radius-tag;
      background-color: $color-grey;
      border: 0.15rem solid $color-black;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem 1.5rem;
      margin-top: 1rem;
      cursor: pointer;
      transition: all $transition-time-default ease;

      &:hover {
        background-color: $color-grey-light-2;
        // color: $color-white;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__specifics {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__heading {
      font-size: $font-size-body-heading-large;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  &__specific {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: $font-size-listing-medium;
    margin-bottom: 1rem;
    cursor: default;

    &:not(:last-child) {
      margin-right: 5rem;
    }

    svg {
      margin-right: 1rem;
      color: $color-primary;
      font-size: $font-size-listing-large;
      transition: all $transition-time-default ease-out;
    }

    &:hover {
      svg {
        transform: translateX(-0.3rem);
      }
    }
  }

  &__nearby {
    height: 100%;
    font-size: $font-size-body-paragraph;
    color: $color-black;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
    min-width: 30%;
    flex: 1;

    @media screen and (max-width: 1400px) {
      width: 100%;
    }

    &__heading {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 1.5rem;
    }

    &__section {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }

    &__location {
      display: flex;
      justify-content: space-between;
      color: $color-grey-dark;
      margin-bottom: 1rem;

      &--metro {
        svg {
          color: #0f69bc;
        }
      }

      &--bus {
        svg {
          color: #e69801;
        }
      }

      &--school {
        svg {
          color: #1f2c46;
        }
      }

      &__name {
        margin-right: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        svg {
          margin-right: 1.5rem;
          font-size: $font-size-body-heading-medium;
        }
      }

      &__distance {
      }
    }
  }

  &__calculator {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 5rem;

    @include respond(tab-port) {
      margin-bottom: 0;
    }
  }

  &__similar {
    width: 100%;

    &__carousel {
      width: 100%;
      padding: 1.5rem 1rem !important;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include respond(phone) {
        justify-content: flex-start;
      }

      .swiper-button-next,
      .swiper-button-prev {
        // position: absolute;
        // top: 1rem;
        border: none;
        border-radius: $border-radius-default;
        color: $color-black;
        background-color: rgba($color: $color-white, $alpha: 0.5);
        z-index: 300;
        height: 4.5rem;
        width: 4.5rem;
        font-size: $font-size-listing-large;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.1s ease-in;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        &:hover,
        &:focus {
          background-color: rgba($color: $color-primary, $alpha: 1);
          box-shadow: $box-shadow-button-hover $color-shadow-primary;
          //   transform: translateY(-0.3rem);
          transform: translateX(-0.3rem);
          color: $color-white;
        }

        &:active {
          //   transform: translateX(-0.15rem);
          //   box-shadow: $box-shadow-button-active $color-shadow-primary;
        }
      }

      .swiper-button-prev {
        margin-right: 0.5rem;
      }

      .swiper-button-next {
        &:hover,
        &:focus {
          transform: translateX(0.3rem);
        }

        svg {
          transform: rotate(180deg);
        }
      }

      .swiper-button-disabled {
        cursor: not-allowed;
        color: $color-grey-light-2;

        &:hover,
        &:focus {
          transform: none;
          box-shadow: none;
          color: $color-grey-light-2;
          background-color: transparent;
        }
      }
    }

    &__listing {
      display: flex;
      flex-direction: column;
      height: 30rem;
      width: 30rem !important;
      border-radius: $border-radius-default;
      transition: all $transition-time-default ease !important;
      z-index: 300 !important;
      box-shadow: $box-shadow-button-active $color-shadow-black;

      @include respond(phone) {
        width: 80% !important;
        margin: 0 5rem !important;
      }

      &--slide {
        position: initial;
        transform: none;
        width: 100%;
        height: 100%;

        &:hover,
        &:focus {
          box-shadow: none !important;
          transform: none !important;
        }
      }

      &:hover,
      &:focus {
        transform: translateY(-0.5rem) !important;
        box-shadow: $box-shadow-button-hover $color-shadow-black;
      }

      &__top {
        width: 100%;
        height: 20rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex: 1;
        position: relative;
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: $border-radius-default;

        &--empty {
          background-color: $color-grey-light-2;
          min-height: 20rem;
        }

        &__image {
          border-top-left-radius: $border-radius-default;
          border-top-right-radius: $border-radius-default;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__heart {
          font-size: $font-size-body-paragraph;

          transition: all $transition-time-default ease;
          transition-delay: 0.3s;
          align-self: center;
          justify-self: center;
          margin: auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          height: fit-content;

          &--fill {
            color: $color-red;
          }

          &__container {
            position: absolute;
            height: 3rem;
            width: 3rem;
            border-radius: 100rem;
            background-color: $color-white;
            box-shadow: 0 0.15rem 0.5rem $color-shadow-black;
            padding: 0.5rem;
            padding-bottom: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 1rem;
            top: 1rem;
            // border: 0.3rem solid $color-black;
            transition: all $transition-time-default ease;
            cursor: pointer;

            &:hover,
            &:focus {
              transform: scale(1.25);

              .map-overlay__listing__window__bottom__heart {
                transform: scale(1.25);
              }
            }
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 1rem;
        width: 100%;
        cursor: pointer;
        // border: 0.15rem solid $color-grey-dark;
        border-top: none;
        border-bottom-left-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;

        &__row {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          * {
            max-width: 45%;
          }

          :first-child {
            align-self: flex-start;
          }

          :last-child {
            align-self: flex-end;
          }
        }
        &__price {
          font-size: $font-size-body-heading-large;
          font-weight: 500;
        }

        &__location {
          font-size: $font-size-body-small;
          align-self: flex-end;
          text-align: right;
        }

        &__type {
          font-size: $font-size-body-paragraph;
          min-width: 80%;
          font-weight: 450;
        }

        &__area {
          font-size: $font-size-body-paragraph;
          font-weight: 400;
        }
      }

      &__image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--none {
          font-size: $font-size-body-heading-small;
        }
      }
    }
  }

  &__broker {
    background-color: $color-grey;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-top: 17.5rem;
    border-radius: $border-radius-default;

    &:hover {
      .listing-page__broker__image {
        transform: scale(1.05);
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      @include respond(tab-port) {
        flex-direction: column;
      }

      &--form {
        flex-direction: column;
        // border-top: 0.15rem solid $color-grey-dark;
        padding: 3rem 2.5rem;
        padding-bottom: 5rem;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 30rem;
      padding-top: 16rem; // Equals 50% of image height + 1rem for the ::before

      @include respond(tab-port) {
        width: 100%;
      }
    }

    &__right {
      display: flex;
      padding: 1.5rem 3rem;
      padding-right: 2.5rem;
      flex: 1;

      @include respond(tab-port) {
        padding-right: 3rem;
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__title {
        margin-bottom: 1rem;
      }

      &__columns {
        display: flex;
        margin-bottom: 1.5rem;

        @include respond(tab-port) {
          flex-direction: column;
        }
      }

      &__button {
        padding: 1rem 1.4rem;
      }

      &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100%;

        @include respond(tab-port) {
          min-height: unset;
        }

        &:first-child {
          margin-right: 3rem;
          max-width: 33%;

          @include respond(tab-port) {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
          }
        }
      }

      &__field {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &--textarea {
          height: 100%;

          @include respond(tab-port) {
            min-height: 20rem;
            margin-top: 2rem;
          }
        }
      }

      // &__label {
      //   font-size: $font-size-body-heading-small;
      //   margin-bottom: 0.3rem;
      //   font-weight: 500;
      //   display: flex;
      //   align-items: center;

      //   svg {
      //     margin-right: 1rem;
      //   }
      // }

      // &__input {
      //   font-size: $font-size-body-paragraph;
      //   border-radius: $border-radius-default;
      //   padding: 0.75rem 1.5rem;
      //   border: 0.15rem solid $color-grey-dark;
      //   transition: all $transition-time-default ease;
      //   cursor: pointer;

      //   &:hover,
      //   &:focus {
      //     border-color: $color-primary;
      //     outline: none;

      //     &::placeholder {
      //       color: $color-primary;
      //     }
      //   }

      //   &--textarea {
      //     // min-height: 15rem;
      //     flex: 1;
      //     // height: 100%;
      //     width: 100%;
      //     resize: vertical;
      //   }
      // }

      &__failed-message {
        margin-top: 0.5rem;
        transition: all $transition-time-default ease-out;
        color: $color-primary;
        &--hidden {
          opacity: 0;
        }

        &--shown {
          opacity: 1;
        }
      }
    }

    &__links {
      padding-left: 2.5rem;
      padding-top: 2.5rem;
      display: flex;
      flex-direction: column;

      @include respond(tab-port) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 2rem;
        row-gap: 2rem;
      }

      @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &--horizontal {
        flex-direction: row;
        padding-left: 0;
        padding-top: 0;
        margin-top: 1rem;

        .listing-page__broker__links__link-container {
          &:not(:last-child) {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
      }

      &__link {
        &,
        &:active,
        &:visited,
        &:link {
          text-decoration: none;
          color: $color-black;
          align-self: center;
          border-bottom: 0.15rem solid transparent;
          transition: all $transition-time-default ease;
        }
      }

      &__link-container {
        display: flex;
        align-items: center;
        width: fit-content;

        &:hover,
        &:focus {
          .listing-page__broker__links__link {
            border-color: $color-primary;
          }
        }

        &:not(:last-child) {
          margin-bottom: 1rem;

          @include respond(tab-port) {
            margin-bottom: 0;
          }
        }

        svg {
          margin-right: 1rem;
          font-size: $font-size-body-heading-medium;
          color: $color-primary;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;

      &__bio {
      }

      &__button {
        margin-top: 1.5rem;
        align-self: flex-start;
      }

      &__link {
        margin-top: 1rem;
      }
    }

    &__image-container {
      width: 30rem;
      height: 30rem;
      position: absolute;
      background-color: $color-white;
      top: 0;
      transform: translateY(-15rem);
      border-radius: 50%;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        background-color: $color-white;
        border-radius: 50%;
        top: -1rem;
        left: -1rem;
        z-index: -1;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all $transition-time-default ease-out;

      &__wrapper {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  &__description {
    font-size: $font-size-listing-default;
    width: 75%;
    display: flex;
    padding-right: 2.5rem;
    align-self: flex-start;

    @include respond(tab-port) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 3rem;
    }
  }

  &__details {
    padding-left: 2.5rem;
    display: flex;
    width: 25%;
    flex-direction: column;
    border-left: 0.15rem solid $color-grey-dark;

    @include respond(tab-port) {
      padding-left: 0;
      padding-top: 2.5rem;
      flex-direction: row;
      width: 100%;
      border-left: none;
      border-top: 0.15rem solid $color-grey-dark;
    }
  }

  &__gallery {
    width: 100%;
    height: 80%;
    border-radius: $border-radius-round;
    position: relative;
    margin-bottom: 2rem;

    @include respond(tab-port) {
      height: 100%;
      max-height: 50rem;
    }

    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(1) translateY(-50%);
      }
      .listing-page__gallery__expand {
        transform: scale(1);
      }
    }

    &__media {
      object-fit: cover !important; // Needed to override Swiper styles
      width: 100%;
      height: 100%;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__video {
      object-fit: cover !important; // Needed to override Swiper styles
      width: 100%;
      height: 100%;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__media-container {
      width: 100%;
      height: 100%;

      &--empty {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;
        // border-bottom: 0.15rem solid $color-black;

        @include respond(tab-port) {
          min-height: 30rem;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      border-radius: $border-radius-default;
      color: $color-black;
      background-color: rgba($color: $color-white, $alpha: 0.5);
      z-index: 300;
      height: 4.5rem;
      width: 4.5rem;
      transform: scale(0) translateY(-50%);
      font-size: $font-size-listing-large;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.1s ease-in;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
        transform: translateY(calc(-50% - 0.15rem));
        color: $color-white;
      }

      &:active {
        transform: translateY(-50%);
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }

    .swiper-button-prev {
      left: 1rem;
    }

    .swiper-button-next {
      right: 1rem;

      svg {
        transform: rotate(180deg);
      }
    }

    &__empty-message {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 1.5rem;
    }

    &__index {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 1rem;
      right: 1rem;
      border-radius: $border-radius-default;
      padding: 0.5rem 1rem;
      font-size: $font-size-listing-default;
      color: $color-white;
      background-color: rgba($color: $color-black, $alpha: 0.5);
      z-index: 1;
      transition: all 0.15s ease-in;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color: $color-black, $alpha: 1);
      }
    }

    &__expand {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 4.5rem;
      height: 4.5rem;
      transform: scale(0);
      font-size: $font-size-listing-large;
      color: $color-black;
      background-color: rgba($color: $color-white, $alpha: 0.5);
      border-bottom-left-radius: $border-radius-default;
      transition: all 0.1s ease-in;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        color: $color-white;
      }
    }
  }

  &__carousel {
    width: 100% !important;
    height: 15% !important;

    border-radius: $border-radius-default;

    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(1) translateY(-50%) !important;
      }
    }

    &--overlay {
      height: 20% !important;
      margin-bottom: 1rem;
      border-radius: $border-radius-default;

      @include respond(tab-port) {
        height: 15% !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        transform: scale(0) translateY(-50%) !important;
      }
    }

    &__slide {
      transition: all $transition-time-default ease;
      border-radius: $border-radius-default;
      height: 100% !important;
      cursor: pointer;

      &:hover {
        .listing-page__carousel__image,
        .listing-page__carousel__video {
          filter: brightness(0.5);
        }

        .listing-page__carousel__video__play-button {
          background-color: $color-primary;
          color: $color-white;
        }
      }

      &--active {
        filter: brightness(0.6);
      }

      &--overlay {
      }
    }

    &__media-container {
      width: 100%;
      height: 100%;

      &--overlay {
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      border-radius: $border-radius-default;
    }

    &__video {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: $border-radius-default;

      &__play-button {
        background-color: rgba($color: $color-white, $alpha: 0.5);
        color: $color-black;
        font-size: $font-size-body-heading-xl;
        border-radius: 50%;
        z-index: 300;
        height: 4.5rem;
        width: 4.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all $transition-time-default ease;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      border-radius: $border-radius-default;
      color: $color-black;
      background-color: rgba($color: $color-white, $alpha: 0.5);
      z-index: 300;
      height: 50%;
      width: 2rem;
      transform: scale(0) translateY(-50%);
      font-size: $font-size-listing-large;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.1s ease-in;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
        color: $color-white;
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }

      &--hidden {
        display: none;
      }
    }

    .swiper-button-prev {
      left: 0;

      svg {
        transform: rotate(180deg);
      }

      &--disabled {
        cursor: not-allowed;
        background-color: rgba($color-grey-dark, 0.5);

        &:hover {
          color: $color-white;
          cursor: not-allowed;
          background-color: rgba($color-grey-dark, 0.5);
        }
      }
    }

    .swiper-button-next {
      right: 0;

      &--disabled {
        cursor: not-allowed;
        background-color: rgba($color-grey-dark, 0.5);

        &:hover {
          color: $color-white;
          cursor: not-allowed;
          background-color: rgba($color-grey-dark, 0.5);
        }
      }
    }
  }

  &__map {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius-default;
    overflow: hidden;

    @media screen and (max-width: 1400px) {
      overflow: unset;
      width: 100%;
      min-width: 100%;
    }

    &__image {
      height: 400px;
      width: 600px;
      border-radius: $border-radius-default;

      @media screen and (max-width: 1400px) {
        width: 100%;
        object-fit: cover;
      }

      @include respond(tab-port) {
        height: auto;
        width: 100%;
      }
    }

    &__icon {
      position: absolute;
      top: -3.75rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 4.3rem;
      background-image: $linear-gradient-primary;
      color: $color-white;
      height: 7.5rem;
      width: 7.5rem;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: $box-shadow-default $color-shadow-primary;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 4.3rem;
    font-weight: 600;
    // align-self: flex-start;

    @include respond(tab-port) {
      width: 100%;
      align-items: flex-start;
    }

    &__top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 2rem;
      width: 100%;
    }

    &__number {
      width: 100%;
      // align-self: flex-start;

      @include respond(tab-port) {
        width: fit-content;
      }
    }

    &::first-letter {
      font-weight: 600;
      margin-right: 0.3rem;
    }

    &__persqm {
      color: $color-grey-dark;
      font-size: 2.3rem;
      align-self: flex-start;

      @include respond(tab-port) {
        align-self: center;
      }
    }

    &__estimate {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      &__inner {
        align-self: flex-start;
        width: fit-content;
      }

      &__indicator {
        font-size: $font-size-body-heading-small;
        // transform: translateX(calc(50% - 0.85rem));
        display: block;

        &--low {
        }

        &--normal {
          transform: translateX(4.6rem);
        }

        &--high {
          transform: translateX(8.7rem);
        }
      }

      &__bars {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;

        &__bar {
          height: 1rem;
          width: 3rem;
          border: 0.3rem solid transparent;

          &:nth-child(1) {
            border-top-left-radius: $border-radius-round;
            border-bottom-left-radius: $border-radius-round;
          }

          &:nth-child(2) {
            border-radius: 0;
          }

          &:nth-child(3) {
            border-top-right-radius: $border-radius-round;
            border-bottom-right-radius: $border-radius-round;
          }

          &--low {
            background-color: transparent;
            border-color: $color-grey-light-2;
          }

          &--normal {
            background-color: $color-grey-light-2;
            border-color: $color-grey-light-2;
          }

          &--high {
            background-color: $color-primary;
          }
        }
      }

      &__sentence {
        font-size: $font-size-body-paragraph;
        font-weight: 400;
        margin-top: 1rem;
      }
    }

    &--negotiable {
      font-size: 3.5rem;
      &::first-letter {
        margin-right: 0;
      }
    }
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 600;
  }

  &__location {
    // margin-top: 2rem;
    font-size: $font-size-listing-medium;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
      color: $color-primary;
      font-size: $font-size-listing-large;
    }
  }
  &__address {
    font-size: $font-size-listing-default;
    color: $color-grey-dark;
  }
  &__floor {
    text-transform: none !important;
  }

  &__stats {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    // margin-top: 2rem;

    &__row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      &--secondary {
        flex-direction: column;
      }
      &:first-child {
        border-bottom: none;
      }
    }
  }

  &__stat {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: $font-size-listing-medium;
    margin-bottom: 1rem;

    svg {
      margin-right: 1rem;
      color: $color-primary;
      font-size: $font-size-listing-large;
      transition: all $transition-time-default ease-out;
    }

    &--secondary {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    strong {
      margin-right: 0.5rem;
    }

    &:not(:last-child) {
      margin-right: 1rem;
      padding-right: 1rem;
    }

    &:hover {
      svg {
        transform: translateY(-0.3rem);
      }
    }
  }

  &__direction {
    // display: flex;
    // flex-wrap: wrap
    // word-break: break-all;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__items {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  &__overlay {
    position: absolute;
    width: 100vw;
    height: 100svh;
    left: 0;
    top: 0;
    background-color: $color-white;
    border-radius: $border-radius-default;
    z-index: 100000;

    &__content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -59%);
      width: 70%;
      height: 70svh;
      z-index: 3;

      @include respond(tab-port) {
        transform: translate(-50%, -50%);
        height: fit-content;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__backdrop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100svh;
      z-index: 1;
      background-color: rgba($color: $color-black, $alpha: 0.85);
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 4.5rem;
      height: 4.5rem;
      font-size: $font-size-listing-large;
      color: $color-white;
      background-color: rgba($color: $color-primary, $alpha: 0.5);
      border-bottom-left-radius: $border-radius-default;
      border-top-right-radius: $border-radius-default;
      transition: all 0.1s ease-in;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        color: $color-white;
      }
    }

    &__gallery {
      width: 100%;
      height: 100%;
      border-top-left-radius: $border-radius-round;
      // position: relative;
      margin-bottom: 3rem;

      &__media {
        object-fit: contain !important; // Needed to override Swiper styles
        width: 100%;
        height: 100%;
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }

      &__media-container {
        width: 100%;
        height: 100%;

        &--empty {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: $color-grey;
          border-bottom: 0.15rem solid $color-black;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        position: absolute;
        top: 50%;
        border-radius: $border-radius-default;
        color: $color-black;
        background-color: $color-grey-light-2;
        z-index: 300;
        height: 4.5rem;
        width: 4.5rem;
        transform: translateY(-50%);
        font-size: $font-size-listing-large;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.1s ease-in;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        &:hover,
        &:focus {
          background-color: rgba($color: $color-primary, $alpha: 1);
          box-shadow: $box-shadow-button-hover $color-shadow-primary;
          transform: translateY(calc(-50% - 0.15rem));
          color: $color-white;
        }

        &:active {
          transform: translateY(-50%);
          box-shadow: $box-shadow-button-active $color-shadow-primary;
        }
      }

      .swiper-button-prev {
        left: 1rem;
      }

      .swiper-button-next {
        right: 1rem;

        svg {
          transform: rotate(180deg);
        }
      }

      &__empty-message {
        font-size: $font-size-body-heading-medium;
        margin-bottom: 1.5rem;
      }

      &__index {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
        border-bottom-left-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;
        padding: 0.5rem 1rem;
        font-size: $font-size-listing-default;
        color: $color-white;
        background-color: rgba($color: $color-black, $alpha: 0.5);
        z-index: 1;
        transition: all 0.15s ease-in;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        &:hover,
        &:active,
        &:focus {
          background-color: rgba($color: $color-black, $alpha: 1);
        }
      }
    }
  }
}
