@import "../abstracts/variables";
@import "../abstracts/mixins";

.add-listing-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70rem;
  height: unset;

  &__top {
    margin-bottom: 3rem;

    &__title {
      font-size: $font-size-body-heading-xl;
      margin-bottom: 1rem;
    }

    &__info {
      font-size: $font-size-body-paragraph;
    }
  }

  .admin-panel-form__gallery__pagination {
    svg {
      margin-right: 1rem;
      align-self: center;
    }
  }
}
