@import "../abstracts/variables";
@import "../abstracts/mixins";

.team-member {
  display: flex;
  padding: 15rem 3rem;

  @include respond(tab-land) {
    flex-direction: column !important;
    clip-path: none !important;
    padding: 5rem 3rem !important;
    margin: 0 !important;
  }

  &:first-child {
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    clip-path: polygon(0 0, 100% 0%, 100% 77%, 0% 100%);
    padding-top: 5rem !important;
  }
  &:last-child {
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;

    margin-top: -11rem;
    padding-bottom: 5rem;

    .team-member__photo-container {
      margin-left: 3rem;
      margin-right: 0;
    }
  }

  &:nth-child(odd) {
    background-color: $color-grey;
    padding-top: 10rem;

    .team-member__photo-container {
      margin-right: 3rem;
      margin-left: 0;
    }
  }

  &:nth-child(even) {
    background-color: $color-grey-light-2;
    flex-direction: row-reverse;
    padding-top: 13rem;

    .team-member__photo-container {
      margin-right: 0;
      margin-left: 3rem;
    }
  }

  // An odd last-child
  &:last-child:nth-child(odd) {
    clip-path: polygon(0 0, 100% 23%, 100% 100%, 0% 100%);
  }

  // An even last-child
  &:last-child:nth-child(even) {
    clip-path: polygon(0 23%, 100% 0%, 100% 100%, 0% 100%);
  }

  // In-between on left side
  &:not(:last-child):not(:first-child):nth-child(odd) {
    margin-top: -10rem;
    padding-top: 7rem;
    padding-bottom: 17rem;
    clip-path: polygon(0 0%, 100% 0%, 100% 77%, 0 100%);
  }

  // In-between on right side
  &:not(:last-child):not(:first-child):nth-child(even) {
    margin-top: -15rem;
    padding-top: 13rem;
    padding-bottom: 17rem;
    clip-path: polygon(0 19%, 100% 0%, 100% 100%, 0 100%);
  }

  &:nth-child(even):not(:last-child) {
  }

  &:hover {
    .team-member__photo-container__photo {
      transform: scale(1.1);
    }
  }

  &__photo-container {
    // width: 30%;
    height: 25rem;
    width: 25rem;
    min-width: 25rem;
    border-radius: 100%;
    overflow: hidden;

    @include respond(tab-land) {
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 1rem;
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 3rem;
    }

    &__photo {
      object-fit: cover;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      transition: all $transition-time-default ease;

      @include respond(tab-land) {
        height: 20rem;
        width: 20rem;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    &__name {
      font-size: $font-size-body-heading-large;
      font-weight: 600;
    }

    &__role {
      font-size: $font-size-body-heading-small;
      text-transform: capitalize;
    }

    &__bio {
      font-size: $font-size-body-paragraph;
      //   text-align: left;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      column-gap: 1rem;
      row-gap: 1rem;

      @include respond(phone) {
        flex-direction: column;
      }

      &__action {
        display: flex;
        align-items: center;

        svg {
          font-size: $font-size-body-heading-small;

          align-self: center;
          margin-right: 1rem;
        }
      }
    }
  }
}
