.social-media-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 40%;
  padding-left: 1rem;
  row-gap: 1rem;
  z-index: 400;

  @include respond(phone) {
    // Positioning
    left: 0;
    top: 0;
    position: static;

    // Size and direction
    width: 100svw;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    // Spacing
    row-gap: 0;
    column-gap: 1rem;

    padding: 1rem 0;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  &__container {
    z-index: 9999;
  }

  &__text {
    font-size: $font-size-body-heading-small;
    font-weight: 600;
    margin-right: 1rem;
  }

  &__icons {
    display: flex;
    column-gap: 1rem;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-body-heading-medium;
    background-color: $color-white;
    color: $color-primary;
    border: 0.15rem solid $color-primary;

    width: 4.3rem;
    height: 4.3rem;
    border-radius: 50%;
    padding: 1rem;

    cursor: pointer;
    transition: all $transition-time-default $transition-function-default;

    &:hover,
    &:focus,
    &:focus-visible {
      transform: translateY(-0.5rem);
      color: $color-white;
      background-color: $color-primary;
      border-color: $color-primary;
      box-shadow: $box-shadow-button-hover $color-shadow-primary;
      outline: none;
    }

    &:active {
      box-shadow: $box-shadow-button-active $color-shadow-primary;
      transform: translateY(-0.2rem);
    }

    @include respond(phone) {
      width: 4rem;
      height: 4rem;

      &:hover,
      &:focus {
        transform: translateY(-0.3rem);
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-primary;
        transform: translateY(-0.1rem);
      }
    }

    &__icon {
    }
  }
}
