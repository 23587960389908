@import "../abstracts/variables";
@import "../abstracts/mixins";

.location-polygon {
  &__box {
    background-color: $color-white;
    border-radius: $border-radius-default;
    padding: 1rem 2rem;
    width: max-content;
  }

  &__name {
    font-size: $font-size-body-paragraph;
    font-weight: 500;
    color: $color-primary;
  }
}
