@import "../abstracts/variables";
@import "../abstracts/mixins";

.button-input {
  position: relative;
  width: fit-content;

  &__input {
    padding-right: 15rem;
    min-width: 25rem;
    overflow-x: scroll;

    &:focus {
      & ~ .button-input__button {
        transform: translateY(-1rem);
      }
    }

    &:valid {
      & ~ .button-input__button {
        border-color: $color-green;
        color: $color-green;

        &:hover,
        &:focus {
          background-color: $color-green;
          color: $color-white;
        }
      }
    }

    &:invalid,
    &:disabled {
      & ~ .button-input__button {
        border-color: $color-primary;
        color: $color-primary;
        cursor: not-allowed;
      }
    }

    &:required:placeholder-shown {
      & ~ .button-input__button {
        transform: scale(0);
      }
    }
    &:placeholder-shown {
      & ~ .button-input__button {
        border-color: $color-grey-dark;
        border-left-color: $color-green;
      }
    }
  }

  &__button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 100%;
    padding: 0 1rem;
    border-top-right-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    border-color: $color-grey-dark;
    transition: all $transition-time-default ease;
    transform: scale(1);

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $color-white;
      border: 0.15rem solid $color-grey-dark;
    }

    svg {
      font-size: $font-size-body-heading-large;
    }
  }
}
