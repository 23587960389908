@import "../abstracts/variables";
@import "../abstracts/mixins";

.results-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;

  &__map {
    border-radius: $border-radius-default;
    overflow: hidden;
    transition: all $transition-time-default ease;
    width: 100%;
    height: 70rem;
  }

  &__body {
    // display: grid;
    // grid-template-columns: 75% 25%;
  }

  &__list {
    // position: relative;
  }

  &__alert-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateY(-13rem);
    margin-bottom: 10rem;

    @include respond(phone) {
      flex-direction: column;
    }

    button {
      &:first-child {
        margin-right: 2rem;

        @include respond(phone) {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }

    &--logged-in {
      flex-direction: column;
      transform: translateY(-5rem);
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }

    &__panel {
      font-size: $font-size-body-paragraph;
      max-width: 45%;
      // height: 20rem;
      padding: 2rem 3rem;
      height: 100%;
      z-index: 300;
      // align-self: center;
      border: 0.15rem solid $color-black;
      border-radius: $border-radius-default;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: all $transition-time-default ease;

      @include respond(tab-port) {
        max-width: 45rem;
      }

      @include respond(phone) {
        max-width: 100%;
      }

      &:hover {
        border-color: $color-primary;
        transform: translateY(-0.5rem);
      }

      &:first-child {
        margin-bottom: 3rem;
      }

      &--alarm {
      }

      &__message {
        color: $color-black;
        margin-bottom: 1.5rem;
      }

      &__input {
        background-color: $color-white;
        margin-bottom: 2rem;
        width: 100%;
        padding: 1rem 2rem;
        outline: none;
        border: 0.15rem solid $color-grey-dark;
        color: $color-black;
        border-radius: $border-radius-default;
        transition: all $transition-time-default ease;
        cursor: pointer;

        &:hover,
        &:focus {
          border-color: $color-primary;
        }
      }
    }
  }
}
