// Base
@import "./base/base";
@import "./base/typography";
@import "./base/animations";
@import "./base/utilities";
@import "animate.css";

// Components
@import "./components/header";
@import "./components/navigation";
@import "./components/footer";
@import "./components/button";
@import "./components/button-input";
@import "./components/code-input";
@import "./components/link";
@import "./components/loader";
@import "./components/profile";
@import "./components/login";
@import "./components/forgotten-password";
@import "./components/reset-password";
@import "./components/chatrooms-list";
@import "./components/chatroom";
@import "./components/chat-message";
@import "./components/admin-panel-menu";
@import "./components/admin-panel-content";
@import "./components/admin-dashboard";
@import "./components/admin-settings-popup";
@import "./components/admin-popup-profile";
@import "./components/admin-transfer-popup";
@import "./components/listing_li";
@import "./components/listing_li_vertical";
@import "./components/listings-list";
@import "./components/clients-list-dashboard";
@import "./components/admin-list";
@import "./components/admin-listings";
@import "./components/admin-panel-form";
@import "./components/admin-colleagues";
@import "./components/client-form";
@import "./components/profile/profile-account";
@import "./components/profile/profile-listings";
@import "./components/profile/profile-searches";
@import "./components/profile/profile-alerts";
@import "./components/profile/profile-saved";
@import "./components/searches-list";
@import "./components/switch-container";
@import "./components/searchbar";
@import "./components/filters-drawer";
@import "./components/map-overlay";
@import "./components/team-member";
@import "./components/icon-input";
@import "./components/map-pin";
@import "./components/tooltip";
@import "./components/sorting-popup";
@import "./components/location-polygon";
@import "./components/button-round";
@import "./components/notary-calculator";
@import "./components/back-to-top-button";
@import "./components/image-masonry";
@import "./components/language-switcher";
@import "./components/social-media-bar";

// Layout & Pages
@import "./layout/admin-panel";
@import "./layout/chat-page";
@import "./layout/admin-panel-screen";
@import "./layout/listing-page";
@import "./layout/profile-page";
@import "./layout/results-page";
@import "./layout/team-page";
@import "./layout/broker-page";
@import "./layout/add-listing-page";
@import "./layout/unsub-page";
@import "./layout/login-page";
@import "./layout/not-found";
@import "./layout/contact-page";
@import "./layout/referral-page";

// Homepage
@import "./layout/homepage";
@import "./components/home/common";
@import "./components/home/services-section";
@import "./components/home/newest-section";
@import "./components/home/partners-section";
@import "./components/home/team-section";

@import "./layout/static-page";

@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

.root__wrapper {
  height: 100svh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  // margin-top: 20rem;

  &--grey {
    background-color: $color-grey;
    // margin-top: 0;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;

    &-thumb {
      background-color: $color-primary;
      border-radius: $border-radius-default;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.w65 {
  width: 65vw;
  max-width: 1300px;
  display: flex;
  align-self: center;

  @include respond(phone) {
    width: 100vw;
    padding: 0 2rem;
  }
}

.admin-site {
  &__wrapper {
    width: 100vw;
    // padding: 0 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 90svh;

    @media screen and (max-width: 1200px) {
      height: fit-content;
      max-height: 90svh;
      flex: 0;
      overscroll-behavior: none !important;
    }
  }
}

* {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;

    &-thumb {
      background-color: $color-primary;
      border-radius: $border-radius-default;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.Toastify__toast {
  font-size: $font-size-default !important;
}

// React-Select overrides
.searchbar__multi-value {
  min-width: fit-content !important;
  margin: 0 !important;
}

.searchbar--vertical
  .searchbar__value-container--is-multi.searchbar__value-container--has-value {
  max-width: unset !important;
}

.searchbar--filter-box
  .searchbar__value-container--is-multi.searchbar__value-container--has-value {
  max-width: unset !important;
}

.searchbar__value-container--is-multi.searchbar__value-container--has-value {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 250px !important;
  overflow-x: scroll !important;
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
  column-gap: 4px !important;

  &::-webkit-scrollbar {
    height: 3px !important;
  }
}

.swiper-lazy-preloader {
  border-color: $color-primary !important;
  border-top-color: transparent !important;
}
