@import "../abstracts/variables";
@import "../abstracts/mixins";

.unsub-from-alert-page {
  width: 100%;
  min-height: 50svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__message {
    font-size: $font-size-body-paragraph;

    &__text {
      padding: 3rem 5rem;
      border-radius: $border-radius-default;

      span {
        display: block;
        margin-top: 1.5rem;
      }

      &--success {
        background-color: rgba($color: $color-green, $alpha: 0.21);
      }

      &--failed {
        background-color: rgba($color: $color-primary, $alpha: 0.21);
      }
    }
  }
}
