@import "../abstracts/variables";
@import "../abstracts/mixins";

.clients-list-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  // padding-top: 1rem;

  &__title {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  &__clients {
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;

    // height: 65%;
    overflow-y: scroll;

    &__icon {
      font-size: 7rem;
      width: 100%;
      align-self: center;
      color: $color-primary;
      margin-bottom: 2rem;
    }
  }

  &__client {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    justify-content: space-between;
    border-bottom: 0.1rem solid $color-black;
    padding-bottom: 1rem;

    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-right: 2rem;
    }

    &__sentence {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;
    }

    &__button {
      &,
      &:active,
      &:focus,
      &:hover,
      &:link,
      &:visited {
        font-size: 2rem;
        color: $color-black;
        text-decoration: none;
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
        border-radius: 50%;
        border: 0.15rem solid $color-black;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition-time-default ease;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
