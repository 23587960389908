.login {
  &__title {
  }

  &__full {
    display: flex;
    flex-direction: column;

    &__field {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__label {
    }

    &__input {
      // max-width: 50rem;
      // font-size: $font-size-default;
      // border-radius: 0.5rem;
      // padding: 0.5rem 2rem;
      // margin-left: 0.3rem;
      // margin-bottom: 1.5rem;
    }

    &__button {
      margin-top: 1.5rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__buttons {
    display: flex;
  }
}
