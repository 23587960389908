@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.profile-account {
  display: flex;
  flex-direction: column;

  &__field {
    display: flex;
    font-size: $font-size-body-heading-small;
    width: fit-content;
    min-width: 30rem;

    &:nth-of-type(1) {
      margin-top: 2rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &--complex {
      flex-direction: column;
      min-width: 30rem;

      .button:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &--textarea {
      min-width: 70%;
      max-width: 75rem;

      .client-form__input--textarea {
        height: 15rem;
        margin-top: 1rem;
      }
    }

    &--interest {
      max-width: 75rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .button-input {
        margin-top: 1rem;
      }

      &__button-row {
        display: flex;
        margin-top: 0.5rem;

        .button:not(:last-child) {
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }
    }

    &__name {
      font-weight: 600;
      display: flex;

      &__action-link {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: flex-start;
        text-transform: capitalize;
        font-weight: 400;
        margin-left: 1.5rem;
        transform: translateY(0.15rem);

        &--no-margin {
          margin-left: 0;
        }
      }

      &__action-loading {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: flex-start;
        text-transform: capitalize;
        font-weight: 400;
        margin-left: 1.5rem;
        transform: translateY(0.15rem);

        color: $color-grey-dark;
        cursor: not-allowed;

        &--no-margin {
          margin-left: 0;
        }
      }
    }

    &__instructions {
      max-width: 55rem;
      margin-bottom: 1rem;
    }

    &__input {
      margin: 1rem 0;
      width: 100%;

      & ~ svg {
        top: 1.75rem;
      }

      &__container {
        display: flex;
      }
    }

    &__value {
    }
  }

  &__auth-steps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &__step {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      @include respond(tab-port) {
        align-items: flex-start;
      }

      &__title {
        font-size: $font-size-body-heading-small;
        font-weight: 600;
        align-self: flex-start;
      }

      &__instructions {
        font-size: $font-size-body-paragraph;
        margin-bottom: 1rem;
        word-break: break-word;
      }

      &__qr {
        border: 0.3rem solid $color-black;
        margin-top: 1.15rem;
        margin-bottom: 2rem;
      }

      &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .code-input {
          margin: 1rem 0;
        }
      }

      &__wrong-code {
        font-weight: 500;
        color: $color-primary;
        margin-top: 1rem;
        align-self: center;
      }

      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }
}
