.not-found {
  display: flex;
  flex-direction: column;
  max-width: 100rem;

  &__title {
    font-size: 5rem;
    font-weight: bold;

    &__code {
      font-size: 7rem;
      margin-right: 2rem;
    }
  }

  &__message {
    font-size: $font-size-body-heading-small;
  }
}
