.forgotten-password {
  &__title {
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__label {
      font-size: $font-size-default;
      margin-bottom: 0.5rem;
    }

    &__input {
      max-width: 50rem;
      font-size: $font-size-default;
      border-radius: 0.5rem;
      padding: 0.5rem 2rem;
      margin-left: 0.3rem;
      margin-bottom: 1.5rem;
    }

    &__button {
      margin-top: 1.5rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
