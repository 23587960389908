@import "../abstracts/variables";
@import "../abstracts/mixins";

.profile-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  &__top {
    display: flex;
    width: 100%;
    margin-bottom: 4.5rem;
    // padding-left: 1rem;

    @media screen and (max-width: 450px) {
      flex-direction: column;
      justify-content: center;
    }

    &__right {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 450px) {
        align-items: center;
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 2rem;

        @media screen and (max-width: 450px) {
          justify-content: space-between;
        }
      }
    }

    &__image {
      height: 100%;
      object-fit: cover;
      transform: scale(1.03);
      transition: all $transition-time-default ease-out;

      &__wrapper {
        height: 20rem;
        width: 20rem;
        border: 0.45rem solid $color-primary;
        border-radius: 50%;
        overflow: hidden;

        @media screen and (max-width: 450px) {
          align-self: center;
        }

        &:hover {
          img {
            transform: scale(1.07);
          }
        }
      }
    }

    &__name {
      align-self: center;
      font-size: $font-size-body-heading-xl;
      padding-left: 3rem;
      margin-left: -0.5rem;
      border-bottom: 0.45rem solid $color-primary;

      @media screen and (max-width: 450px) {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  &__menu {
    display: flex;
    margin-top: 3rem;

    @media screen and (max-width: 850px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }

    &__item {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
      background-color: $color-white;
      z-index: 1;
      border-bottom: 0.3rem solid $color-primary;
      color: $color-grey-dark;

      @media screen and (max-width: 850px) {
        &:nth-child(3),
        &:nth-child(4) {
          border-bottom: none !important;
        }

        &:nth-child(2) {
          border-top-right-radius: $border-radius-round !important;
        }
        &:nth-child(3) {
          border-bottom-left-radius: $border-radius-round !important;
        }
        &:nth-child(4) {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: $border-radius-round !important;
        }
      }

      &,
      &:hover,
      &:focus,
      &:active,
      &:link,
      &:visited {
        text-decoration: none;
      }

      // border-color: $color-grey;
      // margin-bottom: -1rem;
      transition: all $transition-time-default ease-out;
      cursor: pointer;

      &:not(:last-child) {
        // margin-right: 1rem;
        // border-right: 0.3rem solid $color-grey;
      }

      &:first-child {
        border-top-left-radius: $border-radius-round;
        // border-left: 0.3rem solid $color-grey;
      }

      &:last-child {
        border-top-right-radius: $border-radius-round;
        // border-right: 0.3rem solid $color-grey;
      }

      &:hover {
        // background-color: $color-grey;
        background-color: $color-grey-light-2;
        color: $color-black;
        // border-radius: $border-radius-default;
        // transform: translateY(-0.5rem);
      }

      &--current {
        // border-radius: $border-radius-default;
        background-color: $color-primary;
        color: $color-white;
        // transform: translateY(-1rem);

        &:hover {
          background-color: $color-primary;
          color: $color-white;
          // transform: translateY(-1rem);
        }
      }

      &__text {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: $font-size-body-heading-large;
          align-self: center;
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__content {
    // background-color: $color-grey;
    width: 100%;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    padding: 2rem 0;
    flex: 1;

    &__wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-radius: $border-radius-default;
      align-items: flex-start;
    }
  }

  &__tab {
    &__title {
      font-size: $font-size-body-heading-large;
      margin-top: 2rem;
    }
  }
}
