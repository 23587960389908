@import "../abstracts/variables";
@import "../abstracts/mixins";

.chatrooms-list {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  padding-top: 2rem;
  flex: 1;
  height: 90svh;
  padding-right: 3rem;
  padding-left: 3rem;
  background-color: $color-grey;
  overscroll-behavior: none !important;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 1rem 2rem;
  }

  & > &__bottom > .button {
    width: 100%;
  }

  &__title {
    font-size: 3rem;
    text-align: center;
    // margin-top: 0rem;
    margin: 1rem 0;
    // margin-top: 1rem;
    align-content: center;
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($color-black, 0.3);
  }

  &__chatrooms {
    display: flex;
    flex-direction: column;
    background-color: $color-grey;
    overscroll-behavior: none !important;
    flex: 1;
    margin-bottom: 1rem;
    overflow-y: scroll;
    padding-right: 0.5rem;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-right: 0;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0.5rem;

      &-thumb {
        background-color: $color-primary;

        border-radius: $border-radius-default;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &--empty {
      text-align: center;
      justify-content: center;
      align-self: center;
      font-size: 1.7rem;
    }
  }

  &__chatroom {
    display: flex;
    position: relative;
    padding: 2rem 3rem;
    padding-left: 1.5rem;
    min-height: 13rem;
    height: 13rem;
    width: 45rem;
    box-shadow: 0 0.25rem 0.75rem rgba($color-black, 0.15);
    border-radius: $border-radius-default;
    cursor: pointer;
    overflow: hidden;
    background-color: $color-white;
    transition: all 0.25s ease;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      // transform: scale(0);
      background-color: transparent;
      border: 0.15rem solid transparent;
      // border: .1rem solid transparent;
      transition: all 0.25s ease;
      z-index: 0;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &--current {
      // &.chatrooms-list__chatroom--current--active {
      //   &::before {
      //     border-color: $color-green;
      //   }
      // }

      // &.chatrooms-list__chatroom--current--inactive {
      //   &::before {
      //     border-color: $color-red;
      //   }
      // }
      padding-left: 2rem;
      background-color: lighten($color: $color-grey, $amount: 2);

      .chatrooms-list__chatroom__status {
        transform: scaleX(3);
      }
    }

    &:hover:not(.chatrooms-list__chatroom--current) {
      .chatrooms-list__chatroom__status {
        transform: scaleX(2);
      }
    }

    &__name {
      font-size: 1.5rem;
      font-weight: bold;
      margin-right: 1rem;
      display: inline-block;

      @include respond(phone) {
        font-size: 1.7rem;
      }
    }
    &__status {
      display: block;
      position: absolute;
      // border-right: 1rem solid white;
      left: 0;
      top: 0;
      width: 0.55rem;
      height: 100%;
      border-top-left-radius: $border-radius-default;
      border-bottom-left-radius: $border-radius-default;
      transition: all 0.25s ease;

      &--inactive {
        background-color: $color-red;
      }

      &--active {
        background-color: $color-green;
      }
    }

    &__left {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
      flex-shrink: 0;

      &__image {
        height: 7.9rem;
        width: 7.9rem;
        border-radius: 50%;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      &__top,
      &__bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &__unread-count {
      background-color: $color-primary;
      font-size: 1rem;
      font-weight: bold;
      color: $color-white;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__last-message {
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      // max-width: 10rem;

      @include respond(phone) {
        font-size: 1.4rem;
      }
    }

    &__last-message-date {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 100;

      @include respond(phone) {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    padding-right: 0.5rem;
    padding-bottom: 2.5rem;

    &__button {
      transform: scale(1);
      transition: $transition-time-default ease;

      &--hidden {
        transform: scale(0);
      }
    }
  }

  &__create-popup {
    flex-direction: column;
    display: flex;
    align-self: center;
    justify-content: space-between;
    overflow-y: scroll;
    background-color: $color-white;
    border-radius: $border-radius-default;
    box-shadow: 0 0.25rem 0.75rem rgba($color-black, 0.15);
    border: 0.05rem solid $color-black;
    width: 100%;
    height: 100%;
    transform: scale(1);
    padding: 0 2rem;
    margin: 0 1rem;
    transition: $transition-time-default ease;

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin: 0;
    }

    &--hidden {
      transform: scale(0);
    }

    &__wrapper {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 1rem;
      transform: scale(1) translateX(-50%);

      @media screen and (max-width: 1000px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      &--hidden {
        transform: scale(0);
      }
    }

    &__title {
      margin-top: 2rem;
      font-size: 1.9rem;
      text-transform: uppercase;
      text-align: center;
    }

    &__middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 70%;
      // max-height: 500px;

      &__added {
        width: 100%;
        // max-height: 30%;
        // max-height: 40%;
        // height: fit-content;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 40%;

        &__title {
          font-size: 1.7rem;
          margin: 1rem 0;
        }

        &__participants {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex: 1;
          overflow-y: scroll;
        }

        &__participant {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }

          &__name {
            font-size: 1.5rem;
            align-self: center;
          }
        }
      }

      &__all {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        flex: 1;
        height: 40%;

        // max-height: 40%;
        // overflow: auto;

        &__title {
          font-size: 1.7rem;
          margin: 1rem 0;
        }

        &__participants {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex: 1;
          // max-height: 400px;
          overflow-y: scroll;
        }

        &__participant {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }

          &__name {
            font-size: 1.5rem;
            align-self: center;
          }
        }

        &__searchbar {
          font-size: 1.3rem;
          padding: 0.5rem 1.25rem;
          border-radius: $border-radius-default;
          border: 0.1rem solid $color-black;
          margin-bottom: 1.5rem;

          &:hover,
          &:focus {
            outline: none;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      height: fit-content;
      max-height: 4rem;
      width: 100%;
      flex: 1;
      justify-content: space-between;
      // padding: 1rem 2rem;
      margin: 3rem auto;
    }
  }
}
