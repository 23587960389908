@import "../abstracts/variables";
@import "../abstracts/mixins";

.team-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: $font-size-body-heading-xl;
  }

  &__intro {
    font-size: $font-size-body-paragraph;
    margin-bottom: 1rem;

    &--last {
      margin-bottom: 5rem;
    }
  }

  &__members {
    display: flex;
    flex-direction: column;
  }
}
