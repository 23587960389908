@import "../abstracts/variables";
@import "../abstracts/mixins";

.broker-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__top {
    display: flex;

    @include respond(tab-port) {
      flex-direction: column;
    }

    &__left {
      margin-right: 5rem;

      @include respond(tab-port) {
        margin-right: 0;
        align-self: center;
      }

      &__photo {
        width: 30rem;

        @include respond(tab-port) {
          // width: 20rem;
        }
        @include respond(phone) {
          width: 25rem;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      padding-top: 5rem;

      @include respond(tab-port) {
        padding-top: 0;
      }

      &__line {
        font-size: $font-size-body-paragraph;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        svg {
          font-size: $font-size-body-heading-small;
          margin-right: 1rem;
        }

        &:first-child {
          margin-bottom: 0.5rem;
        }
      }

      &__name {
        font-size: $font-size-body-heading-xl;

        @include respond(tab-port) {
          align-self: center;
        }
      }

      &__role {
        text-transform: capitalize;

        @include respond(tab-port) {
          align-self: center;
          margin-bottom: 2rem !important;
        }
      }

      &__email {
      }

      &__phone {
      }

      &__listings {
        b {
          margin: 0 0.5rem;
        }
      }
    }
  }

  &__bio {
    margin-top: 5rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 0.3rem solid $color-grey-light-2;

    &__title {
      font-size: $font-size-body-heading-large;
      margin-bottom: 0.5rem;
    }

    &__content {
      font-size: $font-size-body-paragraph;
    }
  }

  &__contact {
    padding-bottom: 3rem;
    margin-bottom: 2.5rem;
    border-bottom: 0.3rem solid $color-grey-light-2;
    &__title {
      font-size: $font-size-body-heading-large;
      margin-bottom: 1.5rem;
    }
  }

  &__listings {
    margin-top: 2.5rem;

    &__title {
      font-size: $font-size-body-heading-large;
      margin-bottom: -1rem;
    }
  }
}
