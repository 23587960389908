@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-panel-menu {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  height: 100svh;
  overflow-y: scroll;
  // height: 100%;
  justify-content: space-between;
  position: relative;

  &__item {
    font-size: 1.7rem;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all $transition-time-default ease;
    border-top-left-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;

    &:hover,
    &--active {
      background-color: $color-primary;
      color: $color-white;
      transform: translateX(-0.5rem);

      .admin-panel-menu__item__chat-count {
        background-color: $color-grey;
        color: $color-primary;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    &__text {
    }

    &__chat-count {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem;
      font-size: $font-size-body-paragraph;
      font-weight: 500;
      background-color: $color-primary;
      color: $color-white;
      border-radius: 50%;
      height: 2.4rem;
      width: 2.4rem;
      margin-left: 1rem;
      transition: all $transition-time-default $transition-function-default;
    }

    &--profile {
      display: flex;
      padding-left: 1rem;

      &__avatar {
        height: 3.5rem;
        width: 3.5rem;
        margin-right: 1rem;
        object-fit: cover;
        border-radius: 50%;
      }

      &__name {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--exit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        font-size: $font-size-body-heading-large;
      }
    }
  }

  &__logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 1.5rem;

    &__inner {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__logo {
    height: 13rem;
    padding: 1rem 3rem;
    object-fit: contain;
    margin-left: -2rem;
    align-self: center;
  }

  &__top {
    display: flex;
    flex-direction: column;
  }

  &__bottom {
    padding-bottom: 2rem;
  }
}
