@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "@pathofdev/react-tag-input/build/index.css";

.admin-panel-form {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  padding: 2rem 0;
  overflow-y: scroll;

  .swiper-pagination {
    &-current {
      margin-right: 0.15rem;
      margin-left: 1rem;
    }

    &-total {
      margin-left: 0.15rem;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    column-gap: 2rem;
    row-gap: 1.5rem;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    &__field {
      width: 47.5%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--price-negotiable {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        label {
          margin: auto 0;
          width: fit-content;
          margin-left: 1.5rem;
          align-self: center;
          display: flex;
          align-items: center;
        }
      }
      &--displayOnHomepage {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        label {
          margin: auto 0;
          margin-left: 1.5rem;
          align-self: center;
          display: flex;
          align-items: center;
        }
      }
    }

    .button {
      margin-bottom: 1rem;
      font-size: $font-size-body-paragraph;
    }

    &:not(.admin-panel-form__row--address) > * {
      flex: 1;
      column-gap: 3.75rem;
    }

    &--address {
      *:first-child {
        margin-right: 2rem;
      }
    }
  }

  &__text {
    font-size: $font-size-body-paragraph;
    margin-bottom: 0.5rem;
  }

  &__hint {
    font-size: $font-size-listing-small;
    color: $color-grey-dark;
    margin-bottom: 0.5rem;
  }

  .react-datepicker {
    font-size: $font-size-body-paragraph;
    font-family: $font-family-default;

    &__header {
      font-size: $font-size-body-paragraph;
      padding-top: 0.8em;
    }

    &__month {
      margin: 0.4em 1em;
    }

    &__day-name,
    &__day {
      width: 1.9em;
      line-height: 1.9em;
      margin: 0.166em;
    }

    &__current-month {
      font-size: $font-size-body-paragraph;
    }

    &__navigation {
      top: 1rem;
      line-height: 1.7rem;

      &--previous {
        left: 1rem;
      }

      &--next {
        right: 1rem;
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    align-self: flex-start;
    margin-bottom: 2rem;

    &--indented {
      & > label:first-child {
        margin-left: -2rem;
      }

      padding-left: 2rem;

      .admin-panel-form__row {
        margin-bottom: 1rem;
        label:first-child {
          margin-left: 0;
        }
        padding-left: 0;
      }
    }

    &--gallery {
      position: relative;
      display: flex;
      flex: 1;
      height: 100%;
      // max-height: 50%;
      position: relative;

      &:hover {
        .swiper-button-next,
        .swiper-button-prev {
          transform: scale(1) translateY(-50%);
        }
      }
    }

    &--avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      position: relative;
      width: fit-content;
      margin-bottom: 3rem;
      border-radius: 100%;
      // overflow: s;

      &__media {
        width: 25rem;
        margin-bottom: 2rem;
      }

      &__buttons {
        display: flex;

        .button:first-child {
          margin-right: 2rem;
        }
      }
    }

    &--interest {
      .admin-panel-form__input {
        padding: 0;
        border: none;
      }
    }

    &__hint {
      font-size: $font-size-body-paragraph;
    }
  }

  &__input {
    font-size: $font-size-body-paragraph;
    padding: 1rem 1.5rem;
    width: 100%;
    border-radius: $border-radius-default;
    border: 0.15rem solid $color-grey-light;
    outline: none;
    transition: all $transition-time-default;

    // --------------------------------------------
    // .form__label {
    //   margin-left: 2rem;
    //   margin-top: 0.7rem;
    //   display: block;
    //   transition: all 0.3s;
    //   transform: translateY(0rem);
    // }

    &:placeholder-shown ~ .admin-panel-form__label {
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(-4rem);
      transform: translateY(-4rem);
    }
    // --------------------------------------------

    &:hover,
    &:focus {
      border-color: $color-primary;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &--select {
      padding: 0;
      text-transform: capitalize;
      border: none;
      border-radius: $border-radius-default;
    }

    &--textarea {
      min-height: 15rem;
      width: 100%;
      resize: vertical;
    }
  }
  .react-tag-input {
    font-size: $font-size-body-paragraph !important;
  }

  &__label {
    width: 100%;
    font-size: $font-size-body-heading-small;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
    transition: all $transition-time-default;
    transform: translateY(0rem);
  }

  &__input-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    &--switch {
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1rem;
    padding-left: 0.3rem;
    align-items: center;
    border-top: 0.1rem solid $color-black;

    &__button-row {
      display: flex;
      margin-top: 1.5rem;

      .button:first-child {
        margin-right: 3rem;
      }
    }
  }

  &__button {
  }

  &__media {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 1rem 0;
    // height: 20rem;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      height: 0.35rem;
      margin-top: 0.3rem;
    }

    &__container {
      position: relative;
      // max-height: 25rem;
      height: max-content;
      min-width: 100%;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        .admin-panel-form__media__container__overlay {
          transform: scale(1);
        }
      }

      &__media {
        width: 100%;
        height: 100%;
      }

      &__video {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
        cursor: pointer;
      }

      &__overlay {
        background-color: rgba($color: $color-black, $alpha: 0.7);
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: all 0.15s ease-in;
      }

      &__remove {
        background-color: $color-red;
        color: $color-white;
        position: absolute;
        right: -0.25rem;
        top: -0.25rem;
        z-index: 99;
        cursor: pointer;
        padding: 0.5rem 0.75rem;
        border-radius: 50%;
        transition: all 0.3s ease;

        &,
        &:active,
        &:visited,
        &:hover,
        &:focus {
          outline: none;
          border: none;
        }

        &:active,
        &:visited,
        &:hover,
        &:focus {
          background-color: $color-black;
        }

        &__x {
        }
      }
    }
  }

  &__current-activity {
    font-size: $font-size-body-paragraph;
    display: flex;
    align-items: center;
  }

  &__gallery {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-default;

    &__current-badge {
      position: absolute;
      left: 0;
      top: 0;
      padding: 1rem 2rem;
      font-size: $font-size-body-heading-small;
      background-color: $color-primary;
      color: $color-white;
      z-index: 1;
      transition: all $transition-time-default ease-out;

      &--shown {
        transform: translateY(0rem);
      }

      &--hidden {
        transform: translateY(-10rem);
      }
    }

    &__media {
      object-fit: cover !important; // Needed to override Swiper styles
      width: 100%;
      height: 100%;
      max-height: 40rem !important;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__video {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      max-height: 40rem !important;
      cursor: pointer;
    }

    &__media-container {
      width: 100%;
      height: 100%;
      max-height: 40rem !important;

      &--empty {
        min-height: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;
        border-radius: $border-radius-default;
      }
    }

    &__empty-message {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 1.5rem;
    }

    &__button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1rem;
      right: 1rem;
      z-index: 1;
      font-size: $font-size-listing-small;
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;
      border-radius: $border-radius-default;
      border: none;
      padding: 0.5rem 1.5rem;
      transition: all 0.3s ease;

      &--delete {
        background-color: rgba($color: $color-primary, $alpha: 0.5);
      }

      &:hover,
      &:focus,
      &:active {
        background-color: rgba($color: $color-primary, $alpha: 1);
      }
    }

    &__pagination {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 1rem;
      right: 1rem;
      border-radius: $border-radius-default;
      padding: 0.5rem 1rem;
      font-size: $font-size-listing-default;
      color: $color-white;
      background-color: rgba($color: $color-black, $alpha: 0.5);
      z-index: 1;
      transition: all 0.15s ease-in;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color: $color-black, $alpha: 1);
      }
    }

    .swiper-button-hidden {
      display: none !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      border-radius: $border-radius-default;
      color: $color-black;
      background-color: rgba($color: $color-white, $alpha: 0.5);
      z-index: 300;
      height: 4.5rem;
      width: 4.5rem;
      transform: scale(0) translateY(-50%);
      font-size: $font-size-listing-large;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.1s ease-in;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
        transform: translateY(calc(-50% - 0.15rem));
        color: $color-white;
      }

      &:active {
        transform: translateY(-50%);
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }

    .swiper-button-prev {
      left: 1rem;
    }

    .swiper-button-next {
      right: 1rem;

      svg {
        transform: rotate(180deg);
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      padding-top: 1.5rem;
      width: 99%;
      margin-bottom: 1rem;

      &--left {
        justify-content: flex-start;
        column-gap: 2rem;
      }

      // .button:first-child {
      //   margin-right: 2rem;
      // }
    }
  }
}
