@import "../abstracts/variables";
@import "../abstracts/mixins";

.listings-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &--relative {
    position: relative;
  }

  &--vertical {
    .listings-list__top {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &--admin {
    // height: 92% !important;
  }

  &--full-height {
    height: 100%;
  }

  &--client-side {
    .listings-list__listings {
      height: unset;
      padding: 3rem 0;
      padding-top: 1rem;
      padding-right: 1rem;
      border-bottom: 0;
      overflow-y: unset;
      overflow-x: unset;
    }

    .listings-list__controls {
      padding-left: 0.25rem;
      padding-right: 0.7rem;
    }

    .filter-popup {
      &__bg {
        margin: 1rem 0.3rem;
        margin-bottom: 5rem;
        width: 99%;
        height: 99%;
      }
    }
  }

  &__title {
    font-size: 2rem;
    // text-align: center;
    padding-left: 0.5rem;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__controls {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 1.5rem 1rem;
    padding-left: 0.75rem;
    padding-right: 0.7rem;

    &__row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:first-of-type {
        // justify-content: center;
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      @include respond(tab-port) {
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 2rem;
      }
    }

    &__label {
      font-size: $font-size-body-heading-small;
      margin-left: 1rem;
    }

    .switch-container {
      @include respond(tab-port) {
        justify-content: flex-start;
      }

      &__switch {
      }
    }

    &__right {
      display: flex;
      position: relative;
      z-index: 1;

      &--vertical {
        flex-direction: column;
        align-items: flex-end;

        @media screen and (max-width: 1200px) {
          align-items: unset;
        }

        .listings-list__controls__right__items-per-page {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      &__items-per-page {
        height: fit-content;
        display: flex;
        margin-right: 1rem;

        .button {
          font-size: $font-size-body-heading-small;

          box-shadow: none;
          transform: none;

          &:first-of-type {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:nth-of-type(2) {
            border-radius: 0;
            border-left: none;
            border-right: none;
          }

          &:last-of-type {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &__filter-button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: fit-content;
        position: relative;
        z-index: 300;

        svg {
          font-size: $font-size-body-heading-medium;
          margin-right: 0.5rem;
        }
      }
    }

    &__left {
      display: flex;
      align-self: center;
      align-items: center;

      @include respond(tab-port) {
        align-self: flex-start;
      }

      &--vertical {
        flex-direction: column;
        align-items: flex-start;
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;
        margin-top: 1rem;
      }

      &__count {
        font-size: $font-size-body-heading-medium;
        padding-left: 1rem;
        margin-left: 1.5rem;
        border-left: 0.15rem solid $color-grey-dark;
        height: fit-content;
        width: fit-content;
      }

      &__map-button {
        display: flex;
        align-items: center;
        width: fit-content;

        svg {
          font-size: $font-size-body-heading-medium;
          margin-right: 1rem;
        }
      }
    }
  }

  &__listings {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 99%;
    display: flex;
    z-index: 0;
    flex-direction: column;
    padding: 0 0.5rem;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    border-bottom: 0.1rem solid $color-black;

    &::-webkit-scrollbar {
      height: 0.7rem;
    }
    &::-webkit-scrollbar-track {
      margin: 0 0.3rem;
    }
  }

  &__empty {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__message {
      display: flex;
      flex-direction: column;
      font-size: $font-size-body-paragraph;
      align-items: center;
      justify-content: center;

      .button {
        margin: 1rem 0;
      }
    }
  }

  &__sidebar-container {
    position: absolute;
    z-index: 299;
    height: 100%;
    top: 1rem;
    right: -5.7rem;
    padding-bottom: 4rem;

    @include respond(phone) {
      right: 0.75rem;
      z-index: unset;
    }

    &__sidebar {
      position: sticky;
      top: 50%;
      border-radius: $border-radius-default;
      // box-shadow: $box-shadow-list-item;
      z-index: 10;
      transition: all $transition-time-default ease;

      @include respond(phone) {
        z-index: 299;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: -1.3rem;
      //   right: -0rem;
      //   width: 1rem;
      //   height: 1.3rem;
      //   background-image: linear-gradient(
      //     to bottom right,
      //     darken($color-primary, 10%) 0%,
      //     darken($color-primary, 10%) 50%,
      //     transparent 50%,
      //     transparent
      //   );
      // }

      &__panel {
        max-width: 35rem;
        // height: 20rem;
        background-color: $color-primary;
        transform: scale(0);
        position: absolute;
        top: 0;
        left: -36rem;
        transition: all $transition-time-default ease;
        border-radius: $border-radius-default;
        box-shadow: $box-shadow-list-item;
        display: flex;
        flex-direction: column;
        padding: 2rem 3rem;

        &::after {
          content: "";
          position: absolute;
          width: 1rem;
          height: 100%;
          right: -1rem;
          top: 0;
        }

        &--shown {
          transform: scale(1);
        }

        &__title {
          color: $color-white;
          font-size: $font-size-body-heading-medium;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

        &__message {
          color: $color-white;
          font-size: $font-size-body-small;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        &__input {
          margin-bottom: 1rem;

          &:focus {
            transform: none !important;
          }
          &:invalid,
          &:valid {
            border-color: transparent !important;
            &:focus {
              box-shadow: none !important;
            }
          }

          &--select {
            margin-bottom: 0.5rem !important;
            padding: 0 !important;
            border: none !important;
          }
        }
      }

      &__icons {
        display: flex;
        flex-direction: column;

        &__icon-button {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: $font-size-body-heading-xl;
          // padding: 1.5rem 2rem;
          width: 6.5rem;
          height: 6rem;
          background-color: $color-primary;
          border-radius: $border-radius-default;
          color: $color-white;
          border: 0.15rem solid transparent;
          outline: none;
          cursor: pointer;
          transition: all $transition-time-default ease;
          overflow: hidden;

          span {
            position: absolute;
            display: flex;
            align-items: center;

            left: 50%;
            top: 50%;

            opacity: (0);
            transition: all $transition-time-default ease-out;

            &:first-of-type {
              transform: translate(-50%, calc(-50% + 5rem));
            }

            &:last-of-type {
              transform: translate(-50%, calc(-50% - 5rem));
            }

            &.shown {
              transform: translate(-50%, -50%);
              opacity: (1);
            }

            &.hidden {
              opacity: (0);
            }
          }

          // &--border-bottom {
          //   &:first-of-type {
          //     border-bottom: 0.15rem solid $color-primary;
          //   }
          // }

          &--shown {
            transform: scale(1) translateX(0);
          }

          &--hidden {
            transform: translateX(5rem) scale(0);
          }

          &:hover,
          &:focus,
          &--active {
            background-color: $color-white;
            color: $color-primary;
            border-color: $color-primary;
          }

          &:first-of-type {
            &:not(
                .listings-list__sidebar-container__sidebar__icons__icon-button--border-bottom
              ) {
              border-bottom: 0.15rem solid $color-white;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            // margin-bottom: 0.3rem;
          }
          &:nth-of-type(2) {
            border-top-color: transparent;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            // border-bottom-right-radius: 0;
            // margin-bottom: 0.3rem;
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 1rem;

    &__item {
      a {
        padding: 0.5rem 1rem;
        font-size: $font-size-body-heading-small;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all $transition-time-default ease;

        &:hover,
        &:focus {
          background-color: $color-grey-light-2;
          // color: $color-grey-dark;
        }
      }

      &--disabled {
        a {
          color: $color-grey-light;
          cursor: not-allowed;
        }
      }
      &--active {
        a {
          background-color: $color-primary;
          color: $color-white;

          &:hover,
          &:focus {
            background-color: $color-primary;
          }
        }
      }

      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
    }
  }
}
