.language-switcher {
  &__flag {
    display: inline-block;
    width: 2rem;
    height: auto;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

    &--current {
      margin-left: 1rem;
    }

    &--shadow {
    }
  }
}
