@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.newest-section {
  position: relative;

  &__list {
    height: fit-content !important;

    &__more {
      display: flex !important;
      align-items: center;
      justify-content: center;
      transition: all $transition-time-default ease;
      z-index: 300 !important;
      height: 100% !important;
      min-height: 38rem;
      background-color: $color-grey;
      border-radius: $border-radius-default;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__controls-row {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2rem;
    }

    &__listing {
      display: flex;
      flex-direction: column;
      background-color: $color-grey;
      border-radius: $border-radius-default;
      transition: all $transition-time-default ease;
      z-index: 300 !important;
      cursor: pointer;
      height: auto !important;
      // height: 35rem !important;

      &--slide {
        position: initial;
        transform: none;
        width: 100%;

        &:hover,
        &:focus {
          box-shadow: none !important;
          transform: none !important;
        }
      }

      &__top {
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        // flex: 1;
        height: 25rem;
        border-radius: $border-radius-default;

        &--empty {
          background-color: $color-grey-light-2;
        }

        &__heart {
          font-size: $font-size-body-paragraph;

          transition: all $transition-time-default ease;
          transition-delay: 0.3s;
          align-self: center;
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;

          &--fill {
            color: $color-red;
          }

          &__container {
            position: absolute;
            height: 3rem;
            width: 3rem;
            border-radius: 100rem;
            background-color: $color-white;
            box-shadow: 0 0.15rem 0.5rem $color-shadow-black;
            padding: 0.5rem;
            padding-bottom: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 1rem;
            top: 1rem;
            // border: 0.3rem solid $color-black;
            transition: all $transition-time-default ease;
            cursor: pointer;

            &:hover,
            &:focus {
              transform: scale(1.25);

              .map-overlay__listing__window__bottom__heart {
                transform: scale(1.25);
              }
            }
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 1rem;
        width: 100%;
        flex: 1;
        height: 13rem;
        min-height: 13rem;
        max-height: 13rem;
        cursor: pointer;

        &__row {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          * {
            max-width: 45%;
          }

          :first-child {
            align-self: flex-start;
          }

          :last-child {
            align-self: flex-end;
          }
        }
      }

      &__price {
        font-size: $font-size-body-heading-medium;
        font-weight: 500;
      }
      &__location {
        font-size: $font-size-body-small;
        text-align: right;
        display: flex;
        align-self: flex-start !important;
      }

      &__type {
        font-size: $font-size-body-paragraph;
        min-width: 100%;
      }

      &__area {
        font-size: $font-size-body-paragraph;
      }

      &__image {
        width: 100%;
        display: flex;
        align-items: center;
        object-fit: cover;
        justify-content: center;
        border-radius: $border-radius-default;

        &--none {
          font-size: $font-size-body-heading-small;
        }
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 1rem;

    border-radius: $border-radius-default;
    color: $color-black;
    background-color: rgba($color: $color-white, $alpha: 0.5);
    z-index: 300;
    height: 4.5rem;
    width: 4.5rem;
    font-size: $font-size-listing-large;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-in;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover,
    &:focus {
      background-color: rgba($color: $color-primary, $alpha: 1);
      box-shadow: $box-shadow-button-hover $color-shadow-primary;
      //   transform: translateY(-0.3rem);
      transform: translateX(-0.3rem);
      color: $color-white;
    }

    &:active {
      //   transform: translateX(-0.15rem);
      //   box-shadow: $box-shadow-button-active $color-shadow-primary;
    }

    @media screen and (max-width: 700px) {
      position: relative !important;
      top: unset !important;
      left: unset !important;
      right: unset !important;
    }
  }

  .swiper-button-prev {
    right: 5rem;

    @media screen and (max-width: 700px) {
      margin-right: 1rem;
    }
  }

  .swiper-button-next {
    right: 0;

    &:hover,
    &:focus {
      transform: translateX(0.3rem);
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .swiper-button-disabled {
    cursor: not-allowed;
    color: $color-grey-light-2;

    &:hover {
      transform: none;
      box-shadow: none;
      color: $color-grey-light-2;
      background-color: transparent;
    }
  }
}
