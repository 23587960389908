@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-listings {
  &__top {
    &__buttons {
      display: flex;
      padding: 1.5rem;
      column-gap: 1rem;
      padding-bottom: 0;

      @media screen and (max-width: 1200px) {
        position: fixed;
        flex-direction: column;
        bottom: 2rem;
        right: 2rem;
        z-index: 998;
        padding: 0;

        row-gap: 1rem;
      }
    }
  }

  &__title {
    font-size: $font-size-body-heading-xl;
  }

  &__content {
    width: 100%;
    max-width: 100rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  &__drawer {
    &__close {
      display: flex;
      // justify-content: flex-end;
      // padding-right:
      margin: 1rem 0;
    }
  }
}
