@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center_with_transform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGEMENT

/*
0-600px    Phone
600-900px   Tablet Portrait
900-1200px   Tablet Landscape
[1200-1800px]   Desktop (where all normal styles apply)
1800+          Big Desktop
*/

/* $breakpoint argument choices: 
- phone
- tab-port
- tab-land
- big-desktop  
*/

// 1em = 16px

// ORDER in which to write media queries (good practice):
// base + typography -> general layout(headers, footers etc.) + grid -> page layout -> components

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      // 600px
      @content;
    }
  }
  @if $breakpoint == phone-land {
    @media only screen and (min-device-width: 550px) and (max-device-width: 900px) and (orientation: landscape) {
      // Phone Landscape
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      // 900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      // 1200px
      @content;
    }
  }
  @if $breakpoint == big-tab-land {
    @media (max-width: 87.5em) {
      // 1400px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      // 1800px
      @content;
    }
  }
}
