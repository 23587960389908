@import "../abstracts/variables";
@import "../abstracts/mixins";

.filters-drawer {
  width: 80rem !important;
  max-height: 100svh !important;
  z-index: 3000 !important;
  padding: 2rem 2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
    padding: 1rem 2rem;
  }

  & + .EZDrawer__overlay {
    z-index: 2099 !important;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__close {
      margin: 1rem 0;
    }

    &__clear {
      max-width: 40rem;
      height: 70%;
      margin-right: 3rem;
      align-self: center;
      justify-self: flex-start;

      @media screen and (max-width: 1200px) {
        justify-content: center;
        align-items: center;

        column-gap: 1rem;

        svg {
          align-self: center !important;
          justify-self: center !important;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;

    padding: 1rem 2rem;
    padding-left: 0;
    border-top: 0;
    border-bottom: 0;
    background-color: $color-white;
    height: 100%;
    overflow-y: scroll;
    z-index: 10;

    &__heading {
      font-size: $font-size-body-heading-large;
    }
  }
}
