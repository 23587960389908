@import "../abstracts/variables";
@import "../abstracts/mixins";

.navigation {
  width: 65vw;
  align-self: center;
  margin: 3rem 0;
  margin-top: -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond(phone) {
    width: 100%;
    padding: 0 2rem;
    row-gap: 2rem;
  }

  &__left {
  }

  &__middle {
    @include respond(phone) {
      display: flex;
      justify-content: center;
    }
  }

  &__right {
    @include respond(phone) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__back-button {
    display: flex;
    align-items: center;
    font-size: $font-size-listing-medium;
    cursor: pointer;
    width: fit-content;
    color: $color-grey-dark;

    &,
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      text-decoration: none;
    }

    &:hover {
      color: $color-black;

      svg {
        transform: translateX(-1rem);
      }
    }

    svg {
      margin-right: 1.5rem;
      font-size: $font-size-listing-huge;
      transition: all $transition-time-default ease-in;
    }
  }
  &__next-button {
    display: flex;
    align-items: center;
    font-size: $font-size-listing-medium;
    cursor: pointer;
    width: fit-content;
    color: $color-grey-dark;

    &,
    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      text-decoration: none;
    }

    &:hover {
      color: $color-black;

      svg {
        transform: translateX(1rem) rotate(180deg);
      }
    }

    svg {
      margin-left: 1.5rem;
      font-size: $font-size-listing-huge;
      transform: rotate(180deg);
      transition: all $transition-time-default ease-in;
    }
  }
}
