@import "../abstracts/variables";
@import "../abstracts/mixins";

.map-pin {
  position: relative;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 5fr;
  font-size: $font-size-body-paragraph;
  background-color: $color-white;
  border-radius: $border-radius-default;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid $color-primary;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    transition: all $transition-time-default ease;
  }

  &--chosen {
    .map-pin__right {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    padding: 1.7rem;
    border: 0.15rem solid $color-primary;
    border-top-left-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;
    color: $color-primary;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all $transition-time-default ease;

      &.shown {
        transform: translate(-50%, -50%);
        pointer-events: auto;
      }

      &.hidden {
        &:first-child {
          transform: translate(-50%, +7rem);
        }
        &:last-child {
          transform: translate(-50%, -7rem);
        }
        pointer-events: none;
      }
    }
  }
  &__right {
    padding: 0.5rem 1rem;
    font-size: $font-size-body-paragraph;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-top-right-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    border: 0.15rem solid $color-primary;
    color: $color-primary;
    border-left: none;
    transition: all $transition-time-default ease;
  }

  &--default {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: $color-primary;

    &::after {
      content: "";
      width: 14px;
      height: 14px;
      margin: 8px 0 0 8px;
      background: $color-black;
      position: absolute;
      border-radius: 50%;
    }
  }
}
