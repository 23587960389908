@import "../abstracts/mixins";
@import "../abstracts/variables";

// Basic reset

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // Root font-size, defines what 1rem is (1rem = 10px), 10px/16px = 62.5%
  scroll-behavior: smooth;

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px; 12px/16px = 75%
  }
  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px; 9px/16px = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px; 8px/16px = 50%
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family-default;
  // background-color: $color-grey;

  @include respond(tab-port) {
    padding: 0;
  }
}
