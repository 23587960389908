@import "../../abstracts/variables";
@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.profile-searches {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $font-size-body-heading-large;
    margin-bottom: 1.5rem;
  }
}
