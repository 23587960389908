@import "../abstracts/variables";
@import "../abstracts/mixins";

.link {
  font-size: $font-size-body-paragraph;
  color: $color-primary;
  transition: all $transition-time-default ease;
  width: fit-content;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
