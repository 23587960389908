@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-panel-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $color-white;
  width: 100%;
  height: 100%;
  padding: 2rem 5rem;
  padding-top: 1.5rem;
  overflow: hidden;
  flex: 1;

  @media screen and (max-width: 1200px) {
    padding: 1rem 2rem;
    overflow: auto;
    height: fit-content;
  }
}
