@import "../abstracts/variables";
@import "../abstracts/mixins";

.listing-li-vertical {
  display: flex;
  position: relative;
  flex-direction: column;
  //   min-height: 40rem;
  width: 100%;
  border-radius: $border-radius-default;
  border: $border-size-listing-item solid $color-grey-dark;

  // box-shadow: $box-shadow-list-item;
  margin-bottom: 0.3rem;
  transition: $transition-time-default ease;
  margin: 0 0.3rem;

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(1) translateY(-50%);
    }
  }

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  &__confirmation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding: 0 2rem;
    border-radius: $border-radius-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    transform: scale(0);
    transition: $transition-time-default ease;

    &--shown {
      transform: scale(1);
    }

    &--approve {
      background-color: rgba($color: $color-green, $alpha: 0.9);
    }

    &--delete {
      background-color: rgba($color: $color-red, $alpha: 0.9);
    }

    &__message {
      color: $color-white;
      font-size: $font-size-listing-large;
      margin-bottom: 2rem;
    }

    &__buttons {
      display: flex;
    }

    &__button {
      color: $color-white;
      font-size: $font-size-listing-large;

      &:first-child {
        margin-right: 2rem;
      }
    }
  }

  &__badges {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    row-gap: 0.5rem;

    @include respond(phone) {
      justify-content: flex-start;
    }

    &__badge {
      font-size: $font-size-body-small;
      color: $color-white;
      padding: 0.3rem 1.75rem;
      align-self: center;
      // border-radius: $border-radius-round;
      border-radius: $border-radius-tag;
      // border: 0.15rem solid transparent;
      font-weight: 500;
      cursor: default;
      transition: all $transition-time-default ease;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &--purple {
        background-color: $color-purple;
        // border-color: darken($color: $color-purple, $amount: 15);

        &:hover {
          background-color: darken($color: $color-purple, $amount: 10);
        }
      }
      &--grey {
        background-color: $color-grey-dark;
        // border-color: darken($color: $color-grey-dark, $amount: 15);

        &:hover {
          background-color: darken($color: $color-grey-dark, $amount: 10);
        }
      }
      &--orange {
        background-color: $color-orange;
        // border-color: darken($color: $color-orange, $amount: 15);

        &:hover {
          background-color: darken($color: $color-orange, $amount: 10);
        }
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;

    &__top {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      position: relative;
      border-right: none;
      border-top-left-radius: $border-radius-default;

      .swiper-wrapper {
        @include respond(phone) {
          display: flex;
          align-items: center;
        }
      }
      .swiper {
        @include respond(phone) {
          background-color: $color-grey;
          min-height: 100%;
          height: 30rem;
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem;
      padding-right: 0;
      height: 100%;
      transition: all $transition-time-default ease;

      @include respond(tab-port) {
        flex-direction: column;
        padding: 0;
      }

      &__left {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 0;
        width: 100%;
        padding-right: 2rem;
        cursor: pointer;

        @include respond(tab-port) {
          padding: 1rem 1.5rem;
        }

        &__top {
          display: flex;
          width: 100%;

          @include respond(tab-port) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1rem;
          }

          @include respond(phone) {
            margin-bottom: 1rem;
          }

          &:first-child {
            margin-right: 3rem;

            @include respond(tab-port) {
              margin-right: 0;
            }
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;

        .listing-li-vertical__buttons {
          display: flex;
          flex-direction: column;
          flex: 1;

          @include respond(tab-port) {
            flex-direction: row;
            border-top: 0.1rem solid $color-grey-dark;
          }

          &__button {
            &,
            &:active,
            &:focus {
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: center;
              align-items: center;
              font-size: $font-size-body-paragraph;
              text-align: center;
              cursor: pointer;
              outline: none;
              color: $color-black;
              background-color: transparent;
              border: none;
              border-left: $border-size-listing-item solid $color-grey-dark;
              padding: 1rem 2rem;
              transition: all $transition-time-default ease;
              width: 10rem;

              @include respond(tab-port) {
                border-left: none;
              }
            }

            &:not(:last-child) {
              border-bottom: $border-size-listing-item solid $color-grey-dark;

              @include respond(tab-port) {
                border-bottom: none;
                border-right: 0.1rem solid $color-grey-dark;
              }
            }

            svg {
              font-size: 2.1rem;
              margin-bottom: 0.3rem;
            }

            &:hover,
            &:active {
              background-color: $color-primary;
              // background-image: $linear-gradient-primary;
              color: $color-white;
            }

            &--delete {
            }
          }
        }
      }
    }
  }

  &__price {
    display: block;
    align-items: center;
    font-size: $font-size-listing-huge;
    font-weight: 600;

    &::first-letter {
      font-weight: 600;
      margin-right: 0.3rem;
    }

    &--negotiable {
      &::first-letter {
        // font-weight: normal;
        margin-right: 0;
      }
    }
  }

  &__title {
    font-size: $font-size-listing-medium;
    font-weight: 600;
    // font-weight: 700;
    color: darken($color: $color-primary, $amount: 10);
  }

  &__location {
    font-size: $font-size-listing-default;
  }

  &__stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: $font-size-listing-default;
    margin: 1rem 0;
  }

  &__stat {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;

    *:first-child {
      margin-right: 0.3rem;
      align-self: center;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }

    svg {
      font-size: $font-size-listing-large;
    }
  }

  &__description {
    margin-top: 1.5rem;
    font-size: $font-size-listing-default;
    max-height: 100%;
    overflow-y: scroll;
    transition: all 0.3s ease;
    height: 100%;

    &--expanded {
      max-height: 40rem;
    }

    &__button {
      font-size: $font-size-listing-default;
      background-color: transparent;
      color: $color-primary;
      border: none;
      transition: all $transition-time-fast ease;
      align-self: flex-start;
      margin-top: 0.5rem;

      &:hover,
      &:focus {
        color: $color-black;
      }

      &:active {
        color: $color-primary;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
        cursor: pointer;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    color: $color-black;
    flex-shrink: 0;
    font-size: $font-size-listing-small;
    // width: 30%;
    // padding-left: 1rem;
    padding: 1.5rem 1rem;
    border: none;
    border-top: $border-size-listing-item solid $color-grey-dark;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
  }

  &__gallery {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    border-top-left-radius: $border-radius-default;

    &__media {
      object-fit: cover !important; // Needed to override Swiper styles
      width: 100%;
      max-height: 35rem !important;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__video {
      width: 100%;
      height: 100%;
      max-height: 35rem !important;
      object-fit: cover !important;
      cursor: pointer;
    }

    &__media-container {
      width: 100%;
      height: 100%;
      max-height: 35rem !important;

      &--empty {
        height: 100%;
        min-height: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;
        border-bottom: $border-size-listing-item solid $color-black;
      }
    }

    &__empty-message {
      font-size: $font-size-body-heading-medium;
      margin-bottom: 1.5rem;
    }

    &__index {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 1rem;
      right: 1rem;
      border-radius: $border-radius-default;
      padding: 0.5rem 1rem;
      font-size: $font-size-listing-default;
      color: $color-white;
      background-color: rgba($color: $color-black, $alpha: 0.5);
      z-index: 1;
      transition: all 0.15s ease-in;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color: $color-black, $alpha: 1);
      }

      @include respond(phone) {
        right: unset;
        bottom: unset;
        top: 1rem;
        left: 1rem;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      border-radius: $border-radius-default;
      color: $color-black;
      background-color: rgba($color: $color-white, $alpha: 0.5);
      z-index: 300;
      height: 4.5rem;
      width: 4.5rem;
      transform: scale(0) translateY(-50%);
      font-size: $font-size-listing-large;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.1s ease-in;

      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &:hover,
      &:focus {
        background-color: rgba($color: $color-primary, $alpha: 1);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
        transform: translateY(calc(-50% - 0.15rem));
        color: $color-white;
      }

      &:active {
        transform: translateY(-50%);
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }

    .swiper-button-prev {
      left: 1rem;
    }

    .swiper-button-next {
      right: 1rem;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__proposer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border-top: $border-size-listing-item solid $color-grey-dark;
    padding: 1.5rem 0;

    &__button.button.button--outline {
      align-self: center;
      width: fit-content;
    }

    &__info {
      display: flex;
      font-size: $font-size-listing-default;

      &__field {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin-right: 1rem;
          padding-right: 1rem;

          b {
            margin-right: 1rem;
          }
        }
      }

      &__link {
        transition: all $transition-time-default ease;

        &,
        &:active,
        &:visited {
          color: $color-primary;
          text-decoration: none;
        }

        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }
}
