.login-page {
  // position: relative;
  display: flex;
  // flex-direction: column;
  align-items: center;
  // justify-self: flex-start;
  // align-self: flex-start;
  flex: 1;
  width: 100% !important;
  // margin-bottom: 30rem;

  &__image-container {
    width: 45rem;
    height: 70svh;
    align-self: flex-start;
    overflow: hidden;
    border-radius: $border-radius-round;

    @media screen and (max-width: 1400px) {
      display: none;
    }

    &:hover {
      .login-page__image {
        transform: scale(1.1);
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $border-radius-round;
    transition: all $transition-time-default ease-out;
  }

  &__form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex: 1;

    z-index: 1;

    &__container {
      display: flex;
      flex-direction: column;

      border-radius: $border-radius-default;
      box-shadow: $box-shadow-default $color-shadow-black;
      background-color: $color-white;
      padding: 3rem 5.5rem;
      min-width: 40rem;
      max-width: 100rem;
      width: fit-content;
      align-self: center;
      justify-self: center;

      @include respond(phone) {
        min-width: 100%;
        max-width: 100%;

        padding: 2rem 2.5rem;
      }

      &--wide {
        min-width: 60rem;

        @include respond(phone) {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    &__title {
      font-size: $font-size-body-heading-xl;
      margin-bottom: 2rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;

      &__row {
        flex: 1;
        width: 100%;
        column-gap: 2rem;
        display: flex;
        justify-content: space-between;

        @include respond(phone) {
          flex-direction: column;
        }

        &--checkbox {
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 2rem;
          column-gap: 0.3rem;

          @include respond(phone) {
            flex-direction: row !important;
          }

          label {
            align-self: center;
            padding-left: 0;
            margin-top: 0;
          }
        }
      }
      &__column {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;

        .login-page__form__content__field {
          &:last-child {
            margin-left: 0;
          }
        }
      }

      &__field {
        flex: 1;
        margin-bottom: 1rem;

        @include respond(phone) {
          & > input {
            max-width: 100% !important;
            width: 100% !important;
          }
        }

        &__input {
          width: 100%;
          max-width: 30rem;

          @include respond(phone) {
            max-width: 100% !important;
            width: 100% !important;
          }

          &--select {
            width: 50%;
            padding-right: 1rem;
            font-size: $font-size-body-paragraph;
            border-radius: $border-radius-default;
            transition: all $transition-time-default ease;

            @include respond(phone) {
              max-width: 100% !important;
              width: 100% !important;
            }

            &:hover,
            &:focus {
              outline: none;

              &::placeholder {
                color: $color-black;
              }
            }

            &:focus {
              transform: translateY(-1rem);
              box-shadow: $box-shadow-default $color-shadow-black;
            }
          }

          &--checkbox {
            display: none;

            &__box {
              position: relative;
              cursor: pointer;

              width: 1.8rem;
              height: 1.8rem;
              border-radius: $border-radius-default;
              border: 0.15rem solid $color-black;
              margin-right: 0.75rem;

              &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;

                width: 0.9rem;
                height: 0.9rem;
                border-radius: 0.1rem;
                transform: translate(-50%, -50%) scale(0);
                background-color: $color-primary;
                transition: all $transition-time-default ease;
              }

              &--checked {
                &::after {
                  transform: translate(-50%, -50%) scale(1);
                }
              }
            }
          }
        }

        &__label {
          font-size: $font-size-body-paragraph;
          font-weight: 500;
          color: $color-grey-dark-2;
          display: flex;
          align-items: center;
          transform: translateY(0rem);
          transition: all $transition-time-default ease-out;
          padding-left: 1.5rem;
          margin-top: 0.5rem;

          &--2fa {
            margin-top: 0;
            padding-left: 0;
            margin-bottom: 1rem;
            margin-top: -1.5rem;
          }
        }
      }

      &__buttons {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        flex: 1;

        &--column {
          flex-direction: column;
          width: 100%;

          button {
            width: 100%;
            &:not(:first-child) {
              margin-top: 2rem !important;
              margin-left: 0 !important;
            }
          }
        }

        button {
          width: 100%;
          &:not(:first-child) {
            margin-left: 2rem;
          }
        }
      }
      &__links {
        margin-top: 1rem;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
}
