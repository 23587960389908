@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-colleagues {
  height: 100%;

  &__title {
    font-size: $font-size-body-heading-xl;
    align-self: center;
  }
}
