@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800&display=swap");
@import "../abstracts/variables";
@import "../abstracts/mixins";

.heading-primary {
  color: $color-black;
  font-family: $font-family-default;
  font-weight: 900;
  backface-visibility: hidden;
  line-height: 6.5rem;
  margin-bottom: 3rem;
  font-size: 6.1rem;

  @include respond(tab-port) {
    margin-bottom: 3rem;
  }
}

.heading-secondary {
  display: inline-block;
  font-size: 3.8rem;
  text-transform: uppercase;
  font-family: $font-family-default;
  font-weight: bold;
  color: $color-black;
  transition: all 0.2s;
  letter-spacing: 0.2rem;

  &--black {
    color: $color-black;
  }

  &--white {
    color: $color-white;
  }

  @include respond(tab-port) {
    font-size: 3.8rem;
  }

  @include respond(phone) {
    font-size: 3.5rem;
  }
}

.heading-tertiary {
  display: inline-block;
  color: $color-black;
  font-family: $font-family-default;
  font-size: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle {
  font-size: 2.2rem;

  &--black {
    color: $color-black;
  }
}

.paragraph {
  font-size: 1.7rem;

  &--big {
    font-size: 1.9rem;
  }

  &--huge {
    font-size: 2.5rem;
  }

  &--black {
    color: $color-black;
  }

  &--bold {
    font-weight: 700;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.inline-link {
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
  }
}

body {
  font-family: $font-family-default;
  font-weight: 400;
  line-height: 1.7;
}
