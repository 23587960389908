@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "./animations";

// TEXT

.u-center-text {
  text-align: center !important;
}

.u-center-text-tab-port {
  @include respond(tab-port) {
    text-align: center !important;
  }
}

.u-text-right {
  text-align: right !important;
}

.u-text-left {
  text-align: left !important;
}

.u-justify-text {
  text-align: justify !important;
}

.u-justify-text-tab-port {
  @include respond(tab-port) {
    text-align: justify !important;
  }
}

.u-is-bold {
  font-weight: bold !important;
}

// POSITIONING

.u-center-content-absolute {
  @include center_with_transform;
}

.u-position-relative {
  position: relative !important;
}

// MARGINS

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-small-phone {
  @include respond(phone) {
    margin-bottom: 1.5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-huge {
  margin-bottom: 10rem !important;

  @include respond(tab-port) {
    margin-bottom: 8rem !important;
  }
}

.u-margin-bottom-xl {
  margin-bottom: 20rem !important;

  @include respond(tab-port) {
    margin-bottom: 10rem !important;
  }
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-bottom-none-tab-port {
  @include respond(tab-port) {
    margin-bottom: 0 !important;
  }
}

.u-margin-bottom-none-phone {
  @include respond(phone) {
    margin-bottom: 0 !important;
  }
}

.u-margin-bottom-small-tab-port {
  @include respond(tab-port) {
    margin-bottom: 1.5rem !important;
  }
}

.u-margin-bottom-medium-tab-port {
  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-margin-top-small {
  margin-top: 1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
  @include respond(tab-port) {
    margin-top: 3rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-margin-side-medium {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.u-margin-side-big {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

// PADDING

.u-padding-top-medium {
  padding-top: 5rem !important;
}

.u-padding-side-small {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

// ANIMATIONS

.is-shown {
  opacity: 1;
  transform: scale(1);

  &--centered {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.hidden {
  opacity: 0;
  transform: scale(0);

  &--centered {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
}

.noDisplay {
  display: none !important;
}

.u-slide-in-left {
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.u-slide-in-left-solid-fast {
  animation-name: moveInLeftSolid;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
}

.u-slide-in-right-solid-fast-centered {
  animation: moveInLeftSolidCentered 1s ease-out;
}

.u-slide-in-right {
  animation: moveInRight 1s ease-out;
}

.u-slide-in-right-solid-fast-centered {
  animation: moveInRightSolidCentered 1s ease-out;
}

.u-slide-in-right-solid-fast {
  animation: moveInRightSolid 0.6s ease-out;
}

.u-slide-in-bottom {
  animation: moveInBottom 0.8s ease-out;
}

.u-slide-in-bottom-solid {
  animation: moveInBottomSolid 0.8s ease-out;
}

.u-slide-in-bottom-solid-fast {
  animation: moveInBottomSolid 0.6s ease-out;
}

.u-slide-in-bottom-solid-fast-centered {
  animation: moveInBottomSolidCentered 1s ease-out;
}

.u-fade-in {
  animation: fadeIn 1.2s ease-out;
}

.u-slide-in-top {
  animation: moveInTop 1.2s ease-out;
}
.u-slide-in-top-fast {
  animation: moveInTop 0.6s ease-out;
}
.u-slide-in-top-fast-centered {
  animation: moveInTopSolidCentered 0.6s ease-out;
}

.u-inflate {
  animation: inflate 0.5s ease-out;
}

// COLOURS

// .u-bg-color-grey {
//   background-color: $color-grey-light-2 !important;
// }

// .u-is-white-color {
//   color: $color-white !important;
// }

// .u-is-black-color {
//   color: $color-black !important;
// }

// .u-is-primary-color {
//   color: $color-primary !important;
// }

// .u-is-blue-hover {
//   transition: all 0.2s;
//   &:focus,
//   &:hover {
//     color: $color-blue;
//   }
// }

// .u-is-blue-color {
//   color: $color-blue !important;
// }
// .u-is-green-color {
//   color: $color-green !important;
// }

// .u-bg-animated-gradient {
//   background: linear-gradient(
//     -45deg,
//     $color-pink,
//     $color-primary,
//     $color-blue,
//     $color-green
//   );
//   background-size: 400% 400%;
//   animation: gradient 13s ease infinite;
// }
// .u-bg-animated-gradient--primary {
//   background: linear-gradient(-45deg, $color-primary, $color-pink);
//   background-size: 400% 400%;
//   animation: gradient 13s ease infinite;
// }
// .u-bg-animated-gradient--primary--single {
//   background: linear-gradient(
//     to left,
//     $color-grey-light-2,
//     $color-grey-light-2,
//     $color-primary,
//     $color-pink
//   );
//   background-size: 400%;
//   animation: gradient-single 5s;
// }
// .u-bg-animated-gradient--green {
//   background: linear-gradient(
//     -45deg,
//     $color-green,
//     $color-green-light,
//     $color-blue,
//     $color-pink
//   );
//   background-size: 400% 400%;
//   animation: gradient 13s ease infinite;
// }
// .u-bg-animated-gradient--primary-black {
//   background: linear-gradient(
//     -45deg,
//     $color-black-light,
//     $color-primary,
//     $color-pink
//   );
//   background-size: 400% 400%;
//   animation: gradient 13s ease infinite;
// }
// .u-bg-animated-gradient--red {
//   background: linear-gradient(
//     -45deg,
//     $color-pink,
//     $color-pink,
//     $color-black-light
//   );
//   background-size: 400% 400%;
//   animation: gradient 13s ease infinite;
// }

.u-bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

.u-no-scroll {
  overflow: hidden !important;
  height: 100%;
}
