@import "../abstracts/variables";
@import "../abstracts/mixins";

.searchbar {
  position: relative;
  // display: flex;
  // flex-direction: column;

  display: block;

  width: 100%;
  padding: 3rem;
  margin: 5rem 0;
  margin-bottom: 2rem;
  background-color: $color-white;
  border: $border-size-listing-item solid $color-grey-dark;
  transition: all $transition-time-default ease;
  border-radius: $border-radius-default;
  border-top-left-radius: 0;
  z-index: 10;

  @include respond(tab-port) {
    margin-top: 0;
  }

  &--expanded {
  }

  &--filter-box {
    margin: 0;
    z-index: unset;
    // overflow-y: scroll;
    padding: 0;
    border: none;

    .searchbar__inputs {
      display: flex;
      flex-direction: column;
      height: fit-content;
    }

    .searchbar__double-input {
      width: 100%;

      &__input {
        max-width: unset;
        width: 50%;
      }
    }

    .searchbar__buttons {
      grid-template-columns: 1fr;
    }
  }

  &--homepage {
    box-shadow: $box-shadow-button-hover $color-shadow-black;

    @include respond(tab-port) {
      padding-top: 0;
      margin-top: 5rem !important;
    }

    .searchbar__buttons {
      grid-template-columns: 1fr 1fr 1fr 2fr;

      @include respond(tab-port) {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;

        .searchbar__button {
          flex: 1;
          min-width: 180px;
        }
      }
    }
  }

  &--vertical {
    flex-direction: column;
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
    overflow: scroll;

    @include respond(phone) {
      overflow-x: hidden;
    }

    .searchbar__buttons,
    .searchbar__inputs {
      display: flex;
      flex-direction: column;
    }
  }

  &--map-overlay {
    border: none;

    &:not(.searchbar--vertical) {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%) !important;
      width: 65%;
      margin-top: 0;
      box-shadow: $box-shadow-default $color-shadow-black;
      padding-top: 2rem;
    }

    .searchbar__buttons {
      grid-template-columns: 1fr 1fr 3fr;
    }

    .searchbar__button {
      font-size: $font-size-body-paragraph;
    }
  }

  &__option {
    &--is-disabled {
      background-color: $color-grey-dark !important;
      color: $color-white !important;
      text-decoration: line-through;
      cursor: not-allowed !important;
    }
  }

  &__title {
    font-size: $font-size-body-heading-massive;
    padding: 0;
    margin: 0;
    position: absolute;
    top: -4.41rem;
    left: 2.5rem;
    background-color: $color-white;
    padding: 0 0.5rem;
    padding-right: 1rem;
    border-radius: $border-radius-default;
    z-index: 10;

    @include respond(tab-port) {
      position: relative;
      top: unset;
      left: unset;
      padding: 0;
      font-size: $font-size-body-heading-xl;
    }

    @include respond(tab-port) {
      font-size: 2.7rem;
    }

    &--home {
      padding: 0 3rem;
      // box-shadow: $box-shadow-button-hover $color-shadow-black;
      background-color: transparent;
      position: relative;
      top: 0;
      left: 0;

      @include respond(tab-port) {
        position: relative;
        top: unset;
        left: unset;
        padding: 0;
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0.1px;
      width: 100%;
      height: 82.5%;
      background-color: $color-white;
      border-top-left-radius: $border-radius-default;
      border-top-right-radius: $border-radius-default;
      box-shadow: -0.5rem -0.5rem 0.5rem 0 $color-shadow-black;
      border: 0.1rem solid $color-grey-dark;
      border-bottom: none;
      z-index: 1;

      @include respond(tab-port) {
        display: none;
        position: relative;
      }
    }

    &__box {
      position: absolute;
      top: -6.275rem;
      left: -0.1rem;

      @include respond(tab-port) {
        position: relative;
        top: unset;
        left: unset;
        margin: 1.5rem 0;
      }
    }
  }

  &__inputs {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
    margin-bottom: 2rem;
    transition: height $transition-time-default ease;

    @media screen and (max-width: 1500px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1020px) {
      grid-template-columns: 1fr;
    }

    &--short {
      display: flex;

      .searchbar__field {
        margin-right: 2rem;
        width: fit-content;
        width: 30rem;
      }

      .searchbar__double-input {
        // max-width: 30rem;
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-gap: 1.5rem;

    @media screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }
    @include respond(phone) {
      // grid-template-columns: 1fr;
      display: flex;
      flex-direction: column-reverse;
    }

    &--overlay-small {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      align-self: flex-end;
      margin-left: 2rem;

      .searchbar__button {
        align-self: center;
        padding: 1rem 2rem;

        &:hover,
        &:focus,
        &:active {
          transform: translateY(-0.3rem);
          svg {
            transform: none;
          }
        }

        svg {
          margin-right: 0;
          align-self: center;
          height: 2.5rem;
        }
      }
    }
  }

  &__button {
    font-size: $font-size-body-heading-small;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    outline: none;
    border: 0.15rem solid transparent;
    border-radius: $border-radius-default;
    cursor: pointer;
    flex: 1;
    transition: all $transition-time-default ease;

    &:hover,
    &:focus,
    &:active {
      svg {
        transform: translateX(-0.5rem);
      }
    }

    @include respond(phone) {
      justify-content: unset;
      text-align: center;
      width: 100%;
      flex: 1;

      &__text {
        display: flex;
        justify-content: center;
        flex: 1;
      }
    }

    svg {
      margin-right: 1rem;
      transition: transform $transition-time-default ease;
      align-self: center;

      @include respond(phone) {
        justify-self: flex-start;
        align-self: flex-start;
        margin-right: 0;
      }
    }

    &__search-spinner {
      height: 100%;
      max-height: 6.75rem;
      font-size: $font-size-body-heading-xl;
    }

    &--clear {
      background-color: transparent;
      color: $color-primary;

      border-color: $color-primary;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    &--expand,
    &--map {
      background-color: transparent;
      color: $color-black;
      background-color: $color-white;
      border-color: $color-black;

      svg {
        font-size: $font-size-body-heading-large;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $color-black;
        color: $color-white;
      }

      &:disabled {
        background-color: $color-grey-light-2;
        color: $color-grey-dark;
        border-color: $color-grey-dark;
        cursor: not-allowed;

        &:hover {
          > .tooltip {
            pointer-events: initial;
            opacity: 1;
            transform: translateY(0);
          }

          &:hover,
          &:focus,
          &:active {
            svg {
              transform: none;
            }
          }
        }
      }
    }

    &--search {
      color: $color-white;
      background-color: $color-primary;
      font-size: $font-size-body-heading-large;

      &:hover,
      &:focus,
      &:active {
        box-shadow: $color-shadow-primary;

        &:hover,
        &:focus {
          box-shadow: $box-shadow-button-hover $color-shadow-primary;
        }

        &:active {
          box-shadow: $box-shadow-button-active $color-shadow-primary;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    margin-bottom: 2rem;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      column-gap: 2rem;
    }
  }

  &__keywords {
    margin-bottom: 3rem;
  }

  &__label {
    font-size: $font-size-body-paragraph;
    color: $color-black;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    transition: all $transition-time-fast ease;

    svg {
      font-size: $font-size-body-heading-medium;
      margin-right: 1rem;
    }

    &--small-icon {
      svg {
        font-size: $font-size-body-paragraph;
      }
    }
  }

  &__menu {
    font-size: $font-size-body-heading-small !important;
  }

  &__double-input {
    position: relative;
    width: 100%;
    flex: 1;
    transition: all $transition-time-default ease;

    // @media screen and (max-width: 1020px) {
    //   width: 100%;
    //   flex: 1;
    // }

    &__input {
      position: relative;
      font-size: $font-size-body-heading-small;
      padding: 1.1rem 4rem;
      max-width: 50%;
      width: 50%;
      border-radius: $border-radius-default;
      border: 0.15rem solid $color-grey-dark;
      transition: all $transition-time-fast ease;
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: $color-primary;
        outline: none;

        & + .searchbar__double-input__icon {
          color: $color-primary;
        }
      }
      // @media screen and (max-width: 1020px) {
      //   width: 50%;
      // }
    }

    &__left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      padding-right: 1rem;

      &--with-small-icon {
        padding-left: 3.25rem;
      }

      &--extra-padding {
        padding-left: 4rem !important;
      }
    }

    &__right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-align: right;
      padding-left: 1rem;
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: $font-size-body-heading-large;

      &--left {
        left: 1.15rem;
      }

      &--right {
        right: 1.15rem;
      }

      &--big {
        font-size: $font-size-body-heading-medium;
      }

      &--small {
        font-size: $font-size-body-paragraph;
        height: 2rem;

        &.transform-left {
          transform: translate(-0.4rem, -50%);
        }
      }

      &--flipped {
        transform: translateY(-50%) scaleX(-1);
      }
    }
  }

  &__switch-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    padding: 1rem 0rem;
    // padding-right: 3rem;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    transform: translateY(0.35rem);

    &__switch-container {
      display: flex;
      width: 100%;
      // align-items: ;
      justify-content: space-between;

      &:last-child {
        margin-top: 1rem;
      }
    }

    &__switch {
      margin-left: 1rem;
    }

    &__label {
      font-size: $font-size-body-heading-small;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    transition: all $transition-time-default ease;

    &:hover,
    &:focus-within {
      & .searchbar__label {
        color: $color-primary;
      }

      & .icon-input {
        &__input {
          & .searchbar__control {
            border-color: $color-primary;
          }
        }

        &__icon {
          color: $color-primary;
        }
      }

      & .searchbar__double-input {
        &__icon {
          color: $color-primary;
        }
        &__input {
          border-color: $color-primary;
        }
      }
    }

    &__title {
    }

    &__contents {
      display: flex;
    }
  }
}
