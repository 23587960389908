@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-panel {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}
