@import "../abstracts/variables";
@import "../abstracts/mixins";

.chat-message {
  font-size: 1.5rem;
  color: $color-black;
  border-radius: $border-radius-default;
  padding: 1rem 2rem;
  max-width: 40rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0.3rem 0.3rem rgba($color: $color-black, $alpha: 0.3);
  transition: all 0.1s ease;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
  }

  &__name {
    margin-bottom: 0.3rem;
    font-size: 1.1rem;
  }

  &--focused {
    transform: scale(1.1) translateY(-0.3rem);
    background-color: $color-black !important;
    color: $color-white !important;
  }

  &--user {
    align-self: flex-end;
    color: $color-white;
    background-color: $color-primary;
    padding-right: 1.5rem;

    .chat-message__content {
      a {
        &,
        &:link,
        &:active {
          color: $color-white;
          transition: $transition-time-default ease;
        }

        &:focus,
        &:hover {
          color: #51cdff;
        }
      }
    }
  }

  &--foreign {
    align-self: flex-start;
    background-color: $color-grey;
    padding-left: 1.5rem;

    .chat-message__content {
      a {
        &,
        &:link,
        &:active {
          color: $color-black;
          transition: $transition-time-default ease;
        }

        &:focus,
        &:hover {
          color: #51cdff;
        }
      }
    }
  }

  &--admin {
    font-size: 1.5rem;
    color: $color-grey-dark;
    align-self: center;
  }

  &__content {
    max-width: 40rem;
    word-break: break-all;

    &__emoji {
      font-size: 3rem;
    }

    &--editing {
      border-radius: $border-radius-default;
      padding: 0.5rem 1rem;
      align-self: flex-start;
    }

    &--highlighted {
      background-color: yellow;
      color: $color-black;
    }
  }

  &__time {
    font-size: 1rem;
    text-align: right;
  }

  &__context-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0.3rem 0.3rem rgba($color: $color-black, $alpha: 0.3);
    transform: scale(1);
    z-index: 100;
    width: 7.5rem;
    align-self: center;
    background-color: $color-white;
    border: 0.1rem solid $color-black;
    border-radius: $border-radius-default;

    transition: all 0.1s ease;

    &--hidden {
      transform: scale(0);
    }

    &__wrapper {
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(1) translate(-110%, -5%);
      display: flex;
      justify-content: center;
    }

    &__button {
      background-color: $color-white;
      border: none;
      font-size: 1.3rem;
      border-radius: $border-radius-default;
      padding: 1rem 1.5rem;
      transition: $transition-time-default;
      cursor: pointer;

      &:hover {
        background-color: $color-grey;
      }

      &--delete {
        &:hover {
          background-color: $color-red;
          color: $color-white;
        }
      }
      &--save {
      }
      &--close {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 3.15rem;
        // border: 0.15rem solid $color-white;
        width: 1.5rem;
        font-size: 1.1rem;
        position: absolute;
        top: -22.5%;
        left: -12.5%;
        color: $color-white;
        border-radius: 50%;
        background-color: $color-red;
        box-shadow: 0 0.3rem 0.3rem rgba($color: $color-black, $alpha: 0.3);
        // padding: 0.5rem 1rem;

        &:hover {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }
}
