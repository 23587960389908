.loader {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &--full-height {
    height: 100%;
  }

  &__spinner {
    align-self: center;
    margin: 10rem auto;
    max-width: 5rem;

    &--no-margin {
      margin: 0;
    }
  }
}
