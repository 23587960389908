@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-settings-popup {
  display: flex;
  align-self: center;
  justify-content: space-between;
  // flex-direction: column;
  width: 50%;
  height: 70%;
  padding: 2rem 3rem;
  background-color: $color-grey;
  transform: scale(0);
  transition: $transition-time-default ease;
  z-index: 999; // So it appears below the transfer popup (priority)
  border-radius: $border-radius-default;
  overflow: scroll;
  // box-shadow: $box-shadow-large;

  &--shown {
    transform: scale(1);
  }

  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    min-width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0);
    z-index: 999;

    &--shown {
      transform: scale(1);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    transform: scale(0);

    &--shown {
      transform: scale(1);
    }
  }

  &__close {
    align-self: flex-start;
    font-size: 3rem;
    color: $color-red;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.1s ease;

    &:hover {
      color: $color-black;
    }
  }
}
