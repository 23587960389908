.referral-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: $font-size-body-heading-huge;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    z-index: 3;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 2rem;
      max-width: 70rem;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 3rem;

        @media screen and (max-width: 764px) {
          margin-bottom: 1.5rem;
        }
      }
      &:first-child {
        margin-bottom: 3rem;

        .referral-page__body__row__column {
          min-height: 16rem;
        }
      }

      &__column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }
    }

    &__avatar {
      object-fit: cover;
      border-radius: 50%;
      transform: scale(1.05);

      &__container {
        height: 20rem;
        width: 20rem;
        border-radius: 100%;
        border: 5px solid $color-primary;
        overflow: hidden;
      }
    }

    &__field {
      display: flex;
      flex-direction: column;
      min-width: 30rem;

      &:not(:last-child) {
        margin-right: 10rem;
      }

      .client-form__input:placeholder-shown
        ~ .referral-page__body__field__label--sliding {
        transform: translateY(3rem);
      }

      &--reverse {
        flex-direction: column-reverse;

        .client-form__input:placeholder-shown
          ~ .referral-page__body__field__label--sliding {
          transform: translateY(3rem);
        }

        .referral-page__body__field__label--sliding {
          transform: translateY(0);
        }
      }

      &__label {
        color: $color-black;
        font-size: $font-size-body-heading-small;
        font-weight: 600;
        margin-bottom: 1rem;
        padding: 0;

        &--sliding {
          transform: translateY(0);
        }

        &--large {
          font-size: $font-size-body-heading-large;
        }
      }

      &__input {
        font-size: $font-size-body-paragraph;
        width: 100% !important;

        &--textarea {
          min-height: 10rem;
          line-height: 2.3rem;
        }
      }
    }

    &__heading {
      font-size: $font-size-body-heading-large;
      margin-bottom: -1.5rem;
      margin-top: 2rem;
    }

    &__buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        &:last-child {
          margin-left: 2rem;
        }
      }
    }
  }
}
