.contact-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: $font-size-body-heading-huge;
  }

  &__body {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    column-gap: 1rem;

    @media screen and (max-width: 1050px) {
      flex-direction: column;
      row-gap: 2rem;
    }

    &__column {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      &:first-child {
        justify-content: flex-start;
        align-items: flex-start;
      }
      &:last-child {
        // justify-content: flex-end;
        align-items: flex-end;

        @media screen and (max-width: 1050px) {
          align-items: flex-start;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: $font-size-body-paragraph;
        row-gap: 0.5rem;

        &__link {
          &:not(:first-child) {
            margin-left: 0.5rem;
          }
        }

        &__text {
        }

        &__bold {
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
          font-weight: bold;
        }
      }
    }

    &__map {
      display: flex;
      width: 600px;
      max-width: 600px;
      max-height: 400px;
      flex: 1;

      @media screen and (max-width: 1050px) {
        width: 100%;
        max-width: 100%;
      }

      &__image {
        width: 100%;
        height: 100%;
        border-radius: $border-radius-default;

        @media screen and (max-width: 1050px) {
          object-fit: contain;
        }
      }
    }
  }

  &__icon {
    margin-right: 0.5rem;
  }
}
