.image-masonry {
  &__image {
    position: relative;
    border-radius: $border-radius-default;
    overflow: hidden;

    &:hover {
      .image-masonry__image__delete {
        transform: translateY(0);
      }
      .image-masonry__image__open {
        transform: translateX(0);
      }
    }

    &__content {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      &__video-icon {
        color: $color-primary;
        align-self: center;
        justify-self: center;
        font-size: $font-size-body-heading-huge;
      }
    }

    &__index {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.5rem 1rem;
      font-size: $font-size-body-paragraph;
      background-color: $color-primary;
      color: $color-white;
      border-top-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
      cursor: default;
    }

    &__delete {
      position: absolute;
      bottom: 0;
      right: 0;

      transform: translateY(10rem);

      outline: none;
      border: none;
      cursor: pointer;

      padding: 1rem 1.25rem;
      border: 0.1rem solid $color-primary;
      font-size: $font-size-body-heading-medium;
      background-color: $color-primary;
      color: $color-white;
      border-top-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
      transition: all $transition-time-default ease-in-out;

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-primary;
      }
    }
    &__open {
      position: absolute;
      bottom: 0;
      left: 0;

      transform: translateX(-10rem);

      outline: none;
      border: none;
      cursor: pointer;

      padding: 1rem 1.25rem;
      border: 0.1rem solid $color-primary;
      font-size: $font-size-body-heading-medium;
      background-color: $color-primary;
      color: $color-white;
      border-top-right-radius: $border-radius-default;
      border-bottom-left-radius: $border-radius-default;
      transition: all $transition-time-default ease-in-out;

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-primary;
      }
    }
  }
}
