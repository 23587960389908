@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.team-section {
  position: relative;
  margin-bottom: 0 !important;

  &__list {
    width: 100%;
    padding-top: 13rem !important;
    padding-bottom: 10rem !important;
    display: flex !important;

    @media screen and (max-width: 800px) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__member {
      position: relative;
      width: 70rem;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      &:hover {
        .team-section__list__member__photo {
          transform: translateY(-1rem);

          @media screen and (max-width: 800px) {
            transform: translateY(3rem);
          }
        }

        .team-section__list__member__patch {
          transform: translate(-1rem, 1rem);

          @media screen and (max-width: 800px) {
            transform: translate(0, -1rem);
          }
        }
      }

      @media screen and (max-width: 1300px) {
        width: 70%;
      }

      @media screen and (max-width: 800px) {
        width: 90%;
        display: flex;
        flex-direction: column;
      }

      &__wrapper {
        display: flex !important;
        justify-content: center;
        align-items: center;
        justify-self: center !important;
        align-self: center !important;
      }

      &__photo {
        width: 19rem;
        height: 19rem;
        min-width: 19rem;
        min-height: 19rem;
        position: absolute;
        border-radius: 50%;
        top: -11rem;
        right: -5rem;
        z-index: 100;
        box-shadow: $box-shadow-button-active $color-shadow-black;
        transition: all $transition-time-default;

        @media screen and (max-width: 800px) {
          position: relative;
          top: unset;
          right: unset;
          border: 1rem solid $color-white;
          background-color: $color-white;
          box-shadow: none;
          transform: translateY(4rem);
        }
      }
      &__info {
        background-color: $color-grey-light-2;
        color: $color-black;
        padding: 5rem 3rem;
        padding-left: 7rem;

        border-radius: $border-radius-default;
        font-size: $font-size-body-paragraph;
      }

      &__name {
        font-size: $font-size-body-heading-large;
        margin-bottom: -0.3rem;
      }

      &__role {
        margin-bottom: 1.5rem;
      }

      &__bio {
      }

      &__patch {
        position: absolute;
        left: -5rem;
        bottom: -5rem;
        width: 10rem;
        height: 10rem;
        background-color: $color-primary;
        border-radius: $border-radius-default;
        box-shadow: $box-shadow-button-active $color-shadow-black;
        z-index: 10;
        transition: all $transition-time-default ease-out;

        @media screen and (max-width: 800px) {
          position: relative;
          left: unset;
          bottom: unset;
          align-self: flex-end;
          margin-right: 2rem;
          transform: translate(0rem, -3rem);
        }
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 1rem;

    border-radius: $border-radius-default;
    color: $color-black;
    background-color: rgba($color: $color-white, $alpha: 0.5);
    z-index: 300;
    height: 4.5rem;
    width: 4.5rem;
    font-size: $font-size-listing-large;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-in;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover,
    &:focus {
      background-color: rgba($color: $color-primary, $alpha: 1);
      box-shadow: $box-shadow-button-hover $color-shadow-primary;
      //   transform: translateY(-0.3rem);
      transform: translateX(-0.3rem);
      color: $color-white;
    }

    &:active {
      //   transform: translateX(-0.15rem);
      //   box-shadow: $box-shadow-button-active $color-shadow-primary;
    }
  }

  .swiper-button-prev {
    right: 5rem;
  }

  .swiper-button-next {
    right: 0;

    &:hover,
    &:focus {
      transform: translateX(0.3rem);
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .swiper-button-disabled {
    cursor: not-allowed;
    color: $color-grey-light-2;

    &:hover {
      transform: none;
      box-shadow: none;
      color: $color-grey-light-2;
      background-color: transparent;
    }
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;

    &-bullet {
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all $transition-time-default ease-out;
      background-color: $color-grey-light-2;

      &-active {
        background-color: $color-primary;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
