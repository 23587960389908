@import "../abstracts/variables";
@import "../abstracts/mixins";

.button-round {
  border-radius: 50%;
  transition: all $transition-time-default ease;
  outline: none;
  border: none;
  align-self: flex-end;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  z-index: 11;

  @media screen and (max-width: 1200px) {
    width: 6rem;
    height: 6rem;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $color-grey-light-2;
  }

  svg {
    font-size: $font-size-body-heading-xl;

    @media screen and (max-width: 1200px) {
      font-size: $font-size-body-heading-huge;
    }
  }

  &--primary {
    color: $color-primary;

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &--black {
    color: $color-black;

    &:hover,
    &:focus {
      background-color: $color-black;
      color: $color-white;
    }
  }

  &--outline {
    border: 0.15rem solid;

    &-primary {
      border-color: $color-primary;
    }
    &-black {
      border-color: $color-black;
    }
  }

  &--solid {
    border: 0.15rem solid;

    &-primary {
      border-color: $color-primary;
      background-color: $color-primary;
      color: $color-white;
      box-shadow: $box-shadow-button-active $color-shadow-primary;

      &:hover,
      &:focus {
        background-color: $color-white !important;
        color: $color-primary !important;
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }
    }
    &-black {
      border-color: $color-black;
      background-color: $color-black;
      color: $color-white;
      box-shadow: $box-shadow-button-active $color-shadow-black;

      &:hover,
      &:focus {
        background-color: $color-white !important;
        color: $color-black !important;
        box-shadow: $box-shadow-button-hover $color-shadow-black;
      }
    }
  }

  &--animated {
    &--primary {
      &:hover,
      &:focus {
        transform: translateY(-0.3rem);
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }

      &:active {
        transform: none;
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }
    &--black {
      &:hover,
      &:focus {
        transform: translateY(-0.3rem);
        box-shadow: $box-shadow-button-hover $color-shadow-black;
      }

      &:active {
        transform: none;
        box-shadow: $box-shadow-button-active $color-shadow-black;
      }
    }
  }
}
