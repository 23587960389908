@import "../abstracts/variables";
@import "../abstracts/mixins";

.admin-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 1rem;
  padding-bottom: 5rem;
  padding-left: 0.5rem;
  height: 100%;

  &--user-search {
    height: 65%;
  }

  &--staff {
    max-width: 70rem;
    align-self: center;
    padding-bottom: 7rem;
  }

  &__top {
    &__search {
      position: relative;
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;

      &__input {
        font-size: $font-size-body-paragraph;
        border-radius: $border-radius-default;
        border: $border-size-default solid $color-black;
        padding: 0.5rem 1rem;
        margin-bottom: 3rem;
        width: 100%;
      }

      &__switch {
        text-align: right;
        margin-bottom: 1rem;
        margin-top: -2rem; // To neglect the margin bottom of the input above
        align-self: flex-end;
      }
    }
  }

  &__entities {
    overflow-y: scroll;
    padding: 1rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    border-bottom: 0.1rem solid $color-black;
    height: fit-content;
  }

  &__entity {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    font-size: $font-size-body-paragraph;
    box-shadow: $box-shadow-list-item;
    border-radius: $border-radius-default;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &--user {
      .admin-list__entity__body {
        flex-direction: column;

        &__right {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &__confirmation {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      padding: 0 2rem;
      border-radius: $border-radius-default;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      transform: scale(0);
      transition: $transition-time-default ease;

      &--shown {
        transform: scale(1);
      }
      &--delete {
        background-color: rgba($color: $color-red, $alpha: 0.9);
      }

      &__message {
        color: $color-white;
        font-size: $font-size-listing-default;
        margin-bottom: 2rem;
      }

      &__buttons {
        display: flex;
      }

      &__button {
        color: $color-white;
        font-size: $font-size-listing-default;

        &:first-child {
          margin-right: 2rem;
        }
      }
    }

    &__body {
      padding: 1.5rem;
      width: 100%;
      display: flex;
      position: relative;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }

      &--regions,
      &--property-types,
      &--clients {
        flex-direction: column;
      }

      &__left {
        @media screen and (max-width: 1200px) {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
        }

        &__avatar {
          width: 15rem;
          margin-right: 2rem;

          @media screen and (max-width: 1200px) {
            margin-right: 0;
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &__remove {
        position: absolute;
        top: 1rem;
        right: 1.3rem;
        transition: all $transition-time-default ease;

        cursor: pointer;

        &:hover {
          color: $color-red;
        }
      }
    }

    &__field {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &__role {
        text-transform: capitalize;
      }

      &--interest {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__sub-types {
      //   word-break: break-word;
      hyphens: auto;
      width: 100%;
      overflow-y: scroll;
      height: 100%;

      &__sub-type {
        // display: inline-block;
      }
    }

    &__locations {
      //   word-break: break-word;
      hyphens: auto;
      width: 100%;
      overflow-y: scroll;
      height: 100%;

      &__location {
        // display: inline-block;
      }
    }

    &__bold {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      svg {
        margin-right: 0.3rem;
        font-size: $font-size-listing-medium;
      }
    }

    &__link {
      transition: all $transition-time-default ease;

      &,
      &:active,
      &:visited {
        color: $color-primary;
        text-decoration: none;
      }

      &:hover,
      &:focus {
        color: $color-black;
      }
    }

    &__name {
      font-size: 2rem;
    }

    &__interest {
      margin-top: 1.5rem;
    }

    &__bottom {
      width: 100%;
      flex: 1;
      display: flex;
      border-top: $border-size-listing-item solid $color-black;

      &__button {
        flex: 1;
        padding: 1rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        transition: all $transition-time-default ease;

        svg {
          margin-right: 0.3rem;
          font-size: 2.1rem;
        }

        &:first-child {
          border-bottom-left-radius: $border-radius-default;
        }

        &:last-child {
          border-bottom-right-radius: $border-radius-default;
        }

        &:not(:last-child) {
          border-right: $border-size-listing-item solid $color-black;
        }

        &,
        &:hover,
        &:focus,
        &:active {
          outline: none;
          border: none;
        }

        &:hover,
        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }

  &__empty {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &__message {
      font-size: $font-size-body-heading-small;
    }
  }
}
