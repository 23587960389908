@import "../abstracts/mixins";

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInLeftSolid {
  0% {
    transform: translateX(-10rem);
  }

  100% {
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRightSolid {
  0% {
    transform: translateX(10rem);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes moveInRightSolidCentered {
  0% {
    transform: translate(calc(-50% + 10rem), -50%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes moveInLeftSolidCentered {
  0% {
    transform: translate(calc(-50% - 10rem), -50%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes moveInBottomtSolidCentered {
  0% {
    transform: translate(-50%, calc(-50% + 3rem));
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes moveInTopSolidCentered {
  0% {
    transform: translate(-50%, calc(-50% - 3rem));
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottomSolid {
  0% {
    transform: translateY(3rem);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes inflate {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.7);
  }
}

@include respond(tab-land) {
  @keyframes inflate {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1.3);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(2rem);
  }

  100% {
    transform: translateY(2rem);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-single {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes float {
  0% {
    transform: translate(281px, 123px);
  }
  50% {
    transform: translate(281px, 138px);
  }
  100% {
    transform: translate(281px, 123px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-1.2rem);
    transform: translateY(-1.2rem);
  }
  60% {
    -webkit-transform: translateY(-0.7rem);
    transform: translateY(-0.7rem);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-1.2rem);
    -ms-transform: translateY(-1.2rem);
    -webkit-transform: translateY(-1.2rem);
    transform: translateY(-1.2rem);
  }
  60% {
    -moz-transform: translateY(-0.7rem);
    -ms-transform: translateY(-0.7rem);
    -webkit-transform: translateY(-0.7rem);
    transform: translateY(-0.7rem);
  }
}

.example-enter {
  opacity: 0.01;

  &.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.example-leave {
  opacity: 1;

  &.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

@keyframes pulsatingCluster {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
