@import "../abstracts/variables";
@import "../abstracts/mixins";

.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-body-paragraph;
  background-color: $color-black;
  color: $color-white;
  transition: all $transition-time-fast ease-out;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 3rem;
  min-width: 30rem;
  padding: 2rem 2rem;
  border-radius: $border-radius-default;
  border: 0.15rem solid $color-black;
  transform: translateY(-5rem);
  z-index: 300;
  top: -6rem;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-top: 1.5rem solid $color-black;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    // transition: all $transition-time- ease;
  }
}
