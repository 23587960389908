@import "../abstracts/variables";
@import "../abstracts/mixins";

.button {
  display: block;
  font-weight: 500;
  font-size: 1.15rem;
  text-transform: uppercase;
  justify-content: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  // max-width: 50rem;
  border-radius: $border-radius-default-button;
  border: 0.15rem solid transparent;
  cursor: pointer;
  transition: $transition-time-default;

  &:hover,
  &:focus {
    outline: none;
  }

  &--medium {
    font-size: 1.5rem;
    padding: 0.7rem 1.4rem;
  }

  &--large {
    font-size: 1.7rem;
    padding: 1rem 2rem;
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    svg {
      margin: 0 1rem;
      align-self: center;
    }
  }

  svg {
    &:first-child {
      margin-right: 0.5rem;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border-color: $color-grey-dark;
    color: $color-grey-dark;
    // text-decoration: line-through;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      // box-shadow: $box-shadow-button-hover $color-shadow-black !important;
      box-shadow: none;
      color: $color-grey-dark;
      transform: none;
    }
  }

  &--solid {
    &:hover,
    &:focus {
      transform: translateY($translateY-button-hover);
    }

    &:active {
      transform: translateY(0);
    }

    &-active {
      // &:hover,
      // &:focus,
      // &:active {
      transform: translateY($translateY-button-hover);
      // }
    }

    &-black {
      background-color: $color-black;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-black;
        border-color: $color-black;
        box-shadow: $box-shadow-button-hover $color-shadow-black;
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-black;
      }
    }

    &-primary {
      background-color: $color-primary;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-primary;
        border-color: $color-primary;
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }
      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }

    &-red {
      background-color: $color-red;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-red;
        border-color: $color-red;
      }
    }

    &-chat-primary {
      background-color: $color-primary;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-primary;
        border-color: $color-primary;
      }
    }

    &-purple {
      background-color: $color-purple;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-purple;
        border-color: $color-purple;
      }
    }

    &:disabled {
      background-color: $color-grey-dark;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-grey-dark;
        color: $color-white;
        border-color: transparent;
      }
    }
  }

  &--success {
    background-color: $color-green;
    color: $color-white;
    border-color: $color-green;

    svg {
      font-size: $font-size-body-heading-large;
    }

    &:hover,
    &:focus {
      background-color: $color-green;
      color: $color-white;
      box-shadow: $box-shadow-button-hover $color-shadow-green;
      transform: translateY($translateY-button-hover);
    }

    &:active {
      box-shadow: $box-shadow-button-active $color-shadow-green;
      transform: translateY(0);
    }

    &:disabled {
      background-color: $color-green;
      border-color: $color-green;
      color: $color-white;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-green;
        transform: none;
        color: $color-white;
      }
    }
  }

  &--failed {
    background-color: $color-primary;
    color: $color-white;
    border-color: $color-primary;

    svg {
      font-size: $font-size-body-heading-large;
    }

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
      box-shadow: none;
      transform: none;
    }

    &:active {
      box-shadow: none;
      transform: none;
    }

    &:disabled {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-primary;
        transform: none;
        color: $color-white;
      }
    }
  }

  &--outline {
    background-color: transparent;

    &:hover,
    &:focus {
      transform: translateY($translateY-button-hover);
    }

    &:active {
      transform: translateY(0);
    }

    &-active {
      // &:hover,
      // &:focus,
      // &:active {
      transform: translateY($translateY-button-hover);
      // }
    }

    &-white {
      border-color: $color-white;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-white;
        color: $color-black;
      }
    }

    &-black {
      border-color: $color-black;
      color: $color-black;

      &:hover,
      &:focus {
        background-color: $color-black;
        color: $color-white;
        box-shadow: $box-shadow-button-hover $color-shadow-black;
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-black;
      }

      &.button--outline-active {
        // &:hover,
        // &:focus,
        // &:active {
        background-color: $color-black;
        color: $color-white;
        box-shadow: $box-shadow-button-hover $color-shadow-black;
        // }
      }
    }

    &-primary {
      border-color: $color-primary;
      color: $color-primary;

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-primary;
      }
    }

    &-green {
      border-color: $color-green;
      color: $color-green;

      &:hover,
      &:focus {
        background-color: $color-green;
        color: $color-white;
        box-shadow: $box-shadow-button-hover $color-shadow-green;
      }

      &:active {
        box-shadow: $box-shadow-button-active $color-shadow-green;
      }
    }

    &-chat-primary {
      border-color: $color-primary;
      color: $color-primary;

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

  &--icon {
    display: flex;
    justify-content: flex-start;
    font-size: $font-size-body-paragraph;
    padding: 0;
    margin-bottom: 1rem;
    // height: fit-content;
  }

  &__icon {
    display: flex;
    align-self: flex-start;
    height: 100%;
    width: 5rem !important;
    padding: 0.75rem;
    border-right: 0.1rem solid;

    &__svg {
      font-size: $font-size-body-heading-large;
    }
  }

  &__text {
    display: flex;
    flex: 1;
    padding: 0.3rem 1rem;
  }
}
