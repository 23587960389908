@import "../abstracts/variables";
@import "../abstracts/mixins";

.notary-calculator {
  display: flex;
  width: 100%;
  margin-top: 3rem;

  @include respond(tab-land) {
    flex-direction: column;
    align-items: center;
  }

  &__graph-container {
    position: relative;
    width: 40rem;
    height: 30rem;
    align-self: center;
    margin-left: -4.5rem;
    margin-right: 1rem;

    @include respond(tab-land) {
      margin-bottom: 5rem;
      margin-left: 0;
      margin-right: 0;
    }

    @include respond(tab-port) {
      align-self: flex-start;
    }

    @include respond(phone) {
      width: 100% !important;
      min-height: 30rem !important;
      height: auto !important;
    }

    &__graph {
      max-width: 100% !important;
      height: auto !important;
    }

    &__total {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 50%;

      font-size: $font-size-body-heading-xl;
      font-weight: 600;
      text-align: center;
      word-break: break-all;

      &__text {
        display: block;
        font-size: $font-size-body-paragraph;
        font-weight: 500;
        margin-top: -0.5rem;
      }
    }
  }

  &__values {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    &__container {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &__title {
        font-size: $font-size-body-heading-large;
        margin-bottom: 1.5rem;
      }

      &__contents {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include respond(tab-land) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 3rem;
          row-gap: 3rem;
        }
        @include respond(phone) {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          column-gap: 0;
          row-gap: 1rem;
        }
      }
    }

    &__value-box {
      display: flex;
      flex-direction: column;
      font-size: $font-size-body-paragraph;
      margin-bottom: 1rem;

      &:not(:last-child) {
        margin-right: 5rem;
      }

      &__label {
        font-weight: 600;
      }

      &__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      &__color {
        width: 1rem;
        height: 1rem;
        border-radius: 0.15rem;
        margin-right: 0.75rem;

        &--red {
          background-color: $color-primary;
        }

        &--blue {
          background-color: $color-secondary;
        }
      }

      &__at {
        font-weight: normal;
        margin: 0 0.5rem;
      }

      &__current {
        font-weight: 500;
        color: $color-secondary;
      }

      &__input {
        font-size: $font-size-body-paragraph;
        padding: 1rem 1.5rem;
        outline: none;
        border: none;
        font-weight: 500;
        background-color: $color-grey;
        border-radius: $border-radius-default;
        -moz-appearance: textfield;

        &--price {
          // color: $color-primary;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__disclaimer {
    font-size: $font-size-body-small;
    font-style: italic;
    color: $color-grey-dark;
  }
}
