@import "../abstracts/variables";
@import "../abstracts/mixins";

.homepage {
  width: 100%;

  &__gallery {
    width: 100%;
    height: 40rem;
    margin-bottom: 10rem;
    // transform: translateX(-5%);

    @include respond(tab-port) {
      margin-bottom: 0;
    }

    &__instance {
      width: 100%;
      height: 100%;
      border-radius: $border-radius-round;
      pointer-events: none;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
