@import "../abstracts/variables";
@import "../abstracts/mixins";

.sorting-popup {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  padding: 1.5rem 2rem;
  border: 0.1rem solid $color-black;
  background-color: $color-white;
  color: $color-black;
  border-radius: $border-radius-default;
  font-size: $font-size-body-paragraph !important;
  position: absolute;
  top: 5rem;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-3rem);
  transition: all $transition-time-fast ease;
  box-shadow: $box-shadow-button-active $color-shadow-black;
  z-index: 300;

  @include respond(tab-port) {
    right: unset;
  }

  &--shown {
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0);
  }

  &--filter-box {
    position: relative;
    box-shadow: none;
    border: none;
    padding: 0;
    min-width: unset;
    width: 100%;
    transform: none;
    top: 0;
    margin-bottom: 3rem;
    z-index: 10;
  }

  &__field {
    font-size: $font-size-body-paragraph;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &__row {
      display: flex;

      .sorting-popup__radio:last-child {
        margin-left: 2rem;
      }
    }

    &__name {
      font-size: $font-size-body-heading-small;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &__label {
      align-self: center;
      font-weight: 500;
      cursor: pointer;
    }
    &__button {
      position: relative;
      cursor: pointer;

      width: 2rem;
      height: 2rem;
      border-radius: $border-radius-default;
      border: 0.15rem solid $color-black;
      margin-right: 0.75rem;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;

        width: 1rem;
        height: 1rem;
        border-radius: 0.1rem;
        transform: translate(-50%, -50%) scale(0);
        background-color: $color-primary;
        transition: all $transition-time-default ease;
      }

      &--checked {
        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
