@import "../abstracts/variables";
@import "../abstracts/mixins";

.header-placeholder {
  width: 100vw;
  min-height: 25rem;

  @include respond(big-tab-land) {
    min-height: 23rem;
  }
  @include respond(tab-land) {
    min-height: 16rem;
  }
}

.header {
  position: fixed;
  top: 0;
  font-family: $font-family-default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65vw;
  align-self: center;
  z-index: 1000;
  padding: 7rem 1rem;
  transition: all $transition-time-default $transition-function-default;

  @include respond(big-desktop) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @include respond(tab-land) {
    padding: 5rem 0;
  }

  &--shown {
    transform: translateY(0);
  }

  &--hidden {
    transform: translateY(-15rem);
  }

  &--small {
    position: fixed;
    top: 0;
    font-family: $font-family-default;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65vw;
    align-self: center;
    z-index: 1100;
    padding: 2rem 0rem;
    transition: all $transition-time-default $transition-function-default;
    // transform: translateY(-14rem);

    .header__menu__dropdown__item,
    .header__menu__link {
      font-size: $font-size-menu-item-small !important;
    }

    .header__bg {
      background-color: $color-grey;

      &::after {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  &__bg {
    position: absolute;
    width: 100vw;
    bottom: 0;
    height: 100%;
    left: -17.8vw;
    background-color: $color-white;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      width: 100%;
      height: 0.5rem;
      background-color: $color-primary;

      transform: translateX(-100vw);
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-time-default $transition-function-default,
        transform $transition-time-default $transition-function-default 0.2s;
    }
  }

  &__logo {
    height: 6rem;
    align-self: center;
    cursor: pointer;
    z-index: 10;

    @include respond(tab-land) {
      height: 4rem;
    }

    &--horizontal {
      height: 9rem;

      @include respond(big-tab-land) {
        height: 7rem;
      }
      @include respond(tab-land) {
        height: 6rem;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    z-index: 11;

    &__dropdown {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      align-self: center;

      &:hover,
      &:focus-within {
        .header__menu__dropdown__trigger {
          color: $color-white;
          background-color: $color-primary;
          transform: translateY($translateY-button-hover);
          outline: none;

          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      &--left {
        .header__menu__dropdown__content__wrapper {
          right: 0.5rem;
          left: unset;
          border-top-left-radius: $border-radius-default;
          border-top-right-radius: 0;
          border-bottom-left-radius: $border-radius-default;
          border-bottom-right-radius: $border-radius-default;
        }
      }

      &__trigger {
        align-self: center;
        cursor: pointer;
        z-index: 1;

        &:hover,
        &:focus,
        &:active {
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;

          + .header__menu__dropdown__content__wrapper {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: flex-start;
        z-index: 10;
        background-color: $color-white;

        &__filler {
          z-index: 0;
          height: 0rem;
        }

        &__wrapper {
          position: absolute;
          background-color: $color-white;
          overflow: hidden;
          top: 95%;
          left: 0;
          border: 0.15rem solid $color-primary;
          border-top-right-radius: $border-radius-default;
          border-bottom-left-radius: $border-radius-default;
          border-bottom-right-radius: $border-radius-default;
          opacity: 0;
          pointer-events: none;
          transition: all $transition-time-default ease-out;

          &:hover {
            pointer-events: auto;
            opacity: 1;
          }
        }
      }

      &__item {
        justify-content: space-between;

        &,
        &:link,
        &:active,
        &:visited {
          position: relative;
          display: flex;
          color: $color-black;
          font-weight: 600;
          width: 20rem;
          padding: 0.7rem 1.35rem;
          text-decoration: none;
          font-size: $font-size-menu-item;
          text-transform: uppercase;
          transition: all $transition-time-fast ease;
          cursor: pointer;
          align-items: center;
          background-color: $color-white;
          z-index: 10;
        }

        &:hover,
        &:focus {
          color: $color-white;
          background-color: $color-primary;
          outline: none;

          .header__menu__chat-count {
            background-color: $color-white;
            color: $color-primary;
          }
        }

        svg {
          font-size: $font-size-body-heading-large;
        }

        &--badge {
          justify-content: flex-start;
        }
      }
    }

    &__link {
      &,
      &:link,
      &:active,
      &:visited {
        display: flex;
        // flex-direction: column;
        user-select: none;
        justify-content: center;
        align-self: center;
        align-items: center;
        color: $color-black;
        border-radius: $border-radius-default;
        font-weight: 600;

        transition: all $transition-time-medium ease;
        text-decoration: none;
        font-size: $font-size-menu-item;
        text-transform: uppercase;
        padding: 0.7rem 1.35rem;
        z-index: 10;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        @include respond(tab-land) {
          font-size: $font-size-body-small;
        }

        &:hover,
        &:focus {
          color: $color-white;
          background-color: $color-primary;
          // background-image: $linear-gradient-primary;
          transform: translateY($translateY-button-hover);
          outline: none;
        }

        &:active {
          transform: translateY(0);
        }

        svg {
          font-size: $font-size-body-heading-large;
          margin-left: 1rem;
          // margin-right: 1rem;
          font-weight: bold;
        }
      }
      &__avatar {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
        border-radius: 50%;
        margin-left: 1rem;
      }

      &--logout {
        //   color: $color-black;
        //   font-size: $font-size-menu-item;
        //   text-decoration: none;
        //   text-transform: uppercase;
        cursor: pointer;
        //   padding: 0rem 1.5rem;
        //   border-radius: 0.15rem;
        //   border-bottom: 0.3rem solid transparent;
        //   transition: $transition-time-default;
      }

      // &--logout {
      //   padding-top: 0.5rem;
      //   padding-bottom: 0.5rem;

      //   &:hover,
      //   &:focus {
      //     color: $color-white;
      //     background-color: $color-red;
      //     border-bottom-color: transparent;
      //   }
      // }
    }
    &__chat-count {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem;
      font-size: $font-size-body-small;
      background-color: $color-primary;
      color: $color-white;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      margin-left: 0.5rem;
      transition: all $transition-time-default $transition-function-default;
    }
  }
}

.header-admin {
  width: 100vw;
  display: flex;
  padding: 0 3rem;
  padding-left: 5rem;
  padding-top: 1rem;
  height: 10svh;
  padding-bottom: 1rem;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    padding: 1rem 2rem;
  }

  &--hidden {
    display: none;
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 1rem;
  //   background-image: linear-gradient(
  //     to right,
  //     rgba($color-black, 0.5),
  //     transparent
  //   );
  // }

  &__left {
    display: flex;
    align-self: center;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    height: 100%;
  }

  &__middle {
    flex-grow: 1;
  }

  &__right {
    display: flex;
    align-self: center;
    column-gap: 2rem;
  }

  &__logo-box {
    height: 100%;
  }

  &__logo {
    height: 100%;
  }

  &__menu {
    &__item {
      &,
      &:link,
      &:active,
      &:visited {
        background-color: $color-white;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 1rem 2rem;
        color: $color-black;
        text-align: center;
        transition: $transition-time-default ease;
        border: 0.1rem solid $color-black;
        border-radius: $border-radius-default;
        font-size: 1.3rem;
        text-decoration: none;
        cursor: pointer;
      }

      &:hover,
      &:focus,
      &--active {
        color: $color-white !important;
        // background-color: $color-primary !important;
        background-color: none;
        background-image: $linear-gradient-primary;
        border-color: transparent !important;

        box-shadow: $box-shadow-button-hover $color-shadow-primary;
      }

      // &:not(:last-child, .header-admin__logo-box) {
      //   margin-right: 2rem;
      // }

      &--circular {
        position: relative;
        width: 4.5rem;
        padding: 1rem !important;
        height: 4.5rem;
        border-radius: 50% !important;
        font-size: 2rem !important;
        align-self: center;

        &:hover,
        &:focus {
          outline: none;

          .header-admin__menu__item--circular__tooltip {
            transform: scale(1);
          }
        }

        &__count {
          display: block;
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          background-color: $color-primary;
          color: $color-white;
          border-radius: 50%;
          font-size: 1.1rem !important;
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__tooltip {
          display: block;
          padding: 0.5rem 1.5rem;
          background-color: $color-grey-dark;
          font-weight: 600;
          color: $color-white;
          font-size: 0.9rem;
          border-radius: $border-radius-default;
          transition: all 0.15s ease;
          transform: scale(0);

          &__wrapper {
            position: absolute;
            bottom: -3.5rem;
            left: 50%;
            z-index: 101;
            max-width: 20rem;
            transform: translateX(-50%);

            &--admin {
              width: 10rem;
            }
          }
        }
      }
    }
  }
}

.header-mobile {
  z-index: 100000;

  &__logo {
    display: flex;
    justify-content: center;
    width: 25rem;
    margin-bottom: 3rem;
  }

  &__top {
    padding: 4rem 3rem;
    margin-bottom: 3rem;
    &__logo {
      max-width: 16rem;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &__head {
      margin-bottom: 0 !important;

      &__item {
        display: flex;
        align-items: center;
        font-size: $font-size-body-heading-large;
        color: $color-primary;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        text-decoration: none !important;
        transition: all $transition-time-default $transition-function-default;

        &--active {
          background-color: $color-primary !important;
          color: $color-white !important;
        }

        svg {
          margin-left: 1rem;
          font-size: $font-size-body-heading-xl;
        }
      }

      &__arrow {
        font-size: $font-size-body-heading-xl;
        margin-left: 0.5rem;
        transition: all $transition-time-default $transition-function-default;

        &--open {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      display: none;
      flex-direction: column;
      row-gap: 1rem;
      padding-left: 3rem;
      margin-top: 0.5rem;

      .header-mobile__item {
        font-size: $font-size-body-heading-medium;
        margin-bottom: 0 !important;
        background-color: $color-white;
        color: $color-black;
      }

      &--shown {
        display: flex;
      }
    }
  }

  &__item,
  &__dropdown__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem !important;
    border-radius: $border-radius-default;

    font-size: $font-size-body-heading-large;
    color: $color-primary;
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;

    transition: all $transition-time-default $transition-function-default;

    &:focus,
    &:active,
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }

    &:focus > .header-mobile__dropdown__head__item,
    &:active > .header-mobile__dropdown__head__item,
    &:hover > .header-mobile__dropdown__head__item {
      color: $color-white;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    svg {
      margin-left: 1rem;
      font-size: $font-size-body-heading-xl;
    }
    &__avatar {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  &__navigation {
    &__background {
      width: 100vw;
      height: 100svh;
      position: fixed;
      top: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: $color-grey;
      -webkit-transform: translateX(-100vw);
      transform: translateX(-100vw);
      transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
      transition-delay: 0.1s;

      @include respond(big-tab-land) {
        max-width: 300px;
      }
      @include respond(phone) {
        max-width: 100%;
      }
    }

    &__body {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100svh;
      display: flex;
      flex-direction: column;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      z-index: 1500;
      -webkit-transform: translateX(-100vw);
      transform: translateX(-100vw);
      transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);

      @include respond(big-tab-land) {
        max-width: 300px;
      }

      @include respond(phone) {
        max-width: 100%;
      }

      &__logo {
        width: 18rem;

        &__container {
          display: flex;
          width: 100%;
          padding-top: 3rem;
          margin-bottom: 3rem;

          @include respond(big-tab-land) {
            padding-left: 0;
            justify-content: center;
          }
          @include respond(phone) {
            padding-left: 2rem;
            justify-content: flex-start;
          }
        }
      }

      &__links {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: scroll;
        padding: 0 1rem;
      }

      &__bottom {
        &__logout {
          padding: 0 1rem;
          margin-bottom: 1rem;
        }

        &__spacer {
          width: 100%;
          padding: 1rem 2rem;
          background-color: $color-primary;
          -webkit-transform: translateY(100rem);
          transform: translateY(100rem);
          transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
          // transition-delay: .3s;
        }

        &__copy {
          width: 100%;
          display: flex;
          align-items: center;
          color: $color-white;
          font-size: $font-size-body-heading-small;

          svg {
            margin: 0 0.5rem;
            font-size: $font-size-body-paragraph;
          }
        }
      }
    }

    &__button {
      background-color: $color-white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: fixed;
      width: 7rem;
      height: 7rem;
      border-radius: $border-radius-default;
      text-align: center;
      top: 6rem;
      right: 6rem;
      z-index: 2000;
      box-shadow: 0 1rem 3rem rgba($color: $color-black, $alpha: 0.1);
      cursor: pointer;
      transition: all $transition-time-default $transition-function-default;

      @include respond(tab-port) {
        top: 4rem;
        right: 4rem;
      }
      @include respond(phone) {
        top: 3rem;
        right: 3rem;
      }
    }

    &__checkbox {
      display: none;
    }

    // ICON

    &__icon {
      position: relative;
      margin-top: 3.5rem;

      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: $color-grey-dark;
        display: inline-block;
        backface-visibility: hidden;
      }

      &::before,
      &::after {
        transition: all 0.2s;
        content: "";
        position: absolute;
        left: 0;
      }

      &::before {
        transform: translateY(-0.8rem);
      }

      &::after {
        transform: translateY(0.8rem);
      }
    }

    // FUNCTIONALITY

    &__checkbox:checked ~ &__background {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    &__checkbox:checked ~ &__body {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      transition-delay: 0.4s;
    }

    &__checkbox:checked ~ &__body > &__body__bottom > &__body__bottom__spacer {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      transition-delay: 0.6s;
    }

    // Move the horizontal lines a little bit

    &__button:hover &__icon::before,
    &__button:focus &__icon::before {
      transform: translateY(-1rem);
    }
    &__button:hover &__icon::after,
    &__button:focus &__icon::after {
      transform: translateY(1rem);
    }

    &__checkbox:checked + &__button &__icon {
      background-color: transparent;

      &::before {
        transform: translateY(0) rotate(135deg);
      }

      &::after {
        transform: translateY(0) rotate(-135deg);
      }
    }
  }
}
